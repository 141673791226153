import React from "react";
import styles from "./Tracking.module.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { trackingId } from "../../ToolKit/Features/Review/ReviewService";
import { useParams } from "react-router-dom";

const Tracking = () => {
  const { id } = useParams();

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      notes: "",
    },
  });

  const disptach = useDispatch();

  const createTrack = (data) => {
    let payload = {
      id: Number(id),
      notes: data.notes,
    };
    disptach(trackingId(id, payload))
      .unwrap()
      .then(() => {
        console.log("Success");
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Add Tracking Update</h6>
          <form className={styles.form} onSubmit={handleSubmit(createTrack)}>
            <div>
              <label className={styles.label} htmlFor="links">
                Add Track Update
              </label>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <select
                    className={styles.input}
                    {...field}
                    {...register("notes", {
                      required: "Please enter track update.",
                    })}
                  >
                    {" "}
                    <option value="">Select an Item</option>
                    <option value="Order awaiting review">
                      {" "}
                      Order awaiting review
                    </option>
                    <option value="Payment pending confirmation">
                      Payment pending confirmation
                    </option>
                    <option value="Payment confirmed, Purchase in progress">
                      Payment confirmed, Purchase in progress
                    </option>
                    <option value="Purchase of additional orders in progress">
                      Purchase of additional orders in progress
                    </option>
                    <option value="Some items in production, waiting for supplier to deliver, please contact support for more information">
                      Some items in production, waiting for supplier to deliver,
                      please contact support for more information
                    </option>
                    <option value="Reorders and replacements of unavailable items ongoing">
                      Reorders and replacements of unavailable items ongoing
                    </option>
                    <option value="Purchase completed, orders arriving warehouse">
                      Purchase completed, orders arriving warehouse
                    </option>
                    <option value="Inspection and packing in progress">
                      Inspection and packing in progress
                    </option>
                    <option value="Order in Progress">Order in Progress</option>
                    <option value="Orders have shipped out, the shipping receipt will be sent to your WhatsApp within 2 days. Please look out for it in your inbox. Thank you">
                      Orders have shipped out, the shipping receipt will be sent
                      to your WhatsApp within 2 days. Please look out for it in
                      your inbox. Thank you.
                    </option>
                  </select>
                )}
              />
            </div>

            <div className={styles.buttons_container}>
              <button type="submit" className={styles.button}>
                Add Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
