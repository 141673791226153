import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getParcelOrdersById, editParcelOrder} from "../../../../ToolKit/Features/Parcel/ParcelService";
import styles from "./EditParcelOrder.module.css"

const EditMyParcelOrder = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getParcelOrdersById(id));
    }, [dispatch, id]);
  
    // use selector to get the reviewData from the state
    const parcelData = useSelector(
      state => state.parcel.parcelById
    );
  
    const [parcelNo, setParcelNo] = useState(undefined);
    const [qty, setQty] = useState(undefined);
    const [inspect, setInspect] = useState("");
    const [info, setInfo] = useState("");
  
    useEffect(() => {
      if (parcelData) {
        setParcelNo(parcelData?.parcelNumber);
        setQty(parcelData?.quantity);
        setInspect(parcelData?.requestInspection)
        setInfo(parcelData?.otherInformation)
      }
    }, [parcelData]); // add reviewData as a dependency
  
    const updateParcel = (e) => {
      e.preventDefault();
      let payload = {
        parcelNumber:parcelNo,
        quantity:qty,
        requestInspection:inspect,
        otherInformation:info,
        
      };
      dispatch(editParcelOrder(id,payload))
        .unwrap()
        .then(() => {
          // handle success case
          console.log("Order updated successfully");
          // fetch the order data again
          dispatch(getParcelOrdersById(id));
        })
        .catch((error) => {
          // handle error case
          console.error("Error updating order", error);
        });
    };
  
    return (
      <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.content}>
          <h3>Update Parcel</h3>
          <form onSubmit={updateParcel}>
            <div>
              <label htmlFor="link">Parcel Number</label>
              <input
                 onChange={(e) => setParcelNo(e.target.valueAsNumber)}
                value={parcelNo}
                type="number" />
            </div>
            <div>
              <label htmlFor="quantity">Quantity</label>
              <input onChange={(e) => setQty(e.target.valueAsNumber)} value={qty} type="number" />
            </div>
            
            <div>
              <label htmlFor="add">Additional Information</label>
              <textarea type="text"onChange={(e) => setInfo(e.target.value)} value={info} />
            </div>

            <div className={styles.check_inspect}>
              <label htmlFor="inspect">Request Inspection</label>
              <select onChange={(e) => setInspect(e.target.value)} value={inspect} name="inspection" id="inspect">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              <br></br>
            </div>

            <button>Update Parcel</button>
        </form>
      </div>
     </div>
     </div>    
    );
  };
export default EditMyParcelOrder;