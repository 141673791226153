import React, { useEffect } from "react";
import "./UnitParcelReview.css";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { getParcelsShippingInreviewById } from "../../../ToolKit/Features/Review/ReviewService";
import { useDispatch, useSelector } from "react-redux";

const UnitParcelShipReview = () => {
  const { id } = useParams();
  const review = useSelector((state) => state.review.shippingById);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.util);

  useEffect(() => {
    localStorage.setItem("reviewId", id);
    dispatch(getParcelsShippingInreviewById(id));
  }, [dispatch, id]);

  
  if (!review) {
    return <div>Loading....</div>;
  }

  return (
    <div class="userlist-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div class="card">
          <div class="card-header">
            <h2>Parcel Details</h2>
            <span>Order ID: #{review?.id}</span>
          </div>
          <div class="card-body">
            <div class="order-item">
              <p>Username: {review?.userName}</p>
              <p>User Phone Number: {review?.number}</p>
              <p>Email: {review?.email}</p>
              <p>Shipment ID: {review?.trackCode}</p>
              <p>Parcel Status: {review?.status}</p>
              <p>Payment Status: {review?.paymentStatus}</p>
              <p>Amount:{review?.totalPrice} </p>
              <p>Invoice:{review?.invoice} </p>
            </div>
            <div class="order-details-btn-section">
              <button
              class="order-details-btn"
              onClick={() => {
                navigate(`/shipping-details-parcel/${review?.id}`);
              }}
              >
                View
              </button>
              <button  onClick={() => {
                navigate(`/tracking-parcel/${review?.id}`);
              }} class="order-details-btn">
               Add Track
              </button>
              <button  onClick={() => {
                navigate(`/view-track-shipping/${review?.id}`);
              }} class="order-details-btn">
               View Track
              </button>
              <button  class="order-details-btn">
                Download
              </button>
              <button
                onClick={() => {
                  navigate(`/edit-parcel-shipping/${review?.id}`);
                }}
                class="order-details-btn"
              >
                Edit
              </button>
              <button  class="payment-btn">
                View Track
              </button>
             
          
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UnitParcelShipReview;
