import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editExchange, getExchangeById } from "../../ToolKit/Features/Referral/ReferralService";
import styles from "./EditMyParcel.module.css"

const EditExchange = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExchangeById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const exchangeData = useSelector((state) => state?.referral?.exchangeId)
  const [usd, setUsd] = useState("");
  const [yen, setYen] = useState("");


  useEffect(() => {
    if (exchangeData) {
    
      setUsd(exchangeData?.usd);
      setYen(exchangeData?.yen);
    }
  }, [exchangeData]); 

  const updateParcel = (e) => {
    e.preventDefault();
    let payload = {
      usd: usd,
      yen: yen,
    };
    dispatch(editExchange(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getExchangeById(id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Edit Rate</h6>
          <form className={styles.form} onSubmit={updateParcel}>
            
            <label className={styles.label} htmlFor="quantity">
              USD
            </label>
            <input
              className={styles.input}
              onChange={(e) => setUsd(e.target.value)}
              value={usd}
            />

           


            <label className={styles.label} htmlFor="inspect">
              Yuan
            </label>

            <input
              className={styles.input}
              onChange={(e) => setYen(e.target.value)}
              value={yen}
              name="inspection"
              id="inspect"
            />

            <br></br>
            <div className={styles.buttons_container}>
            <button className={styles.button}>Update Exchange Rate</button>
            </div>

           
          </form>
        </div>
      </div>
    </div>
  );
  };
export default EditExchange;