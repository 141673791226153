import api from "../axiosRequest";

export const SaveLinkOrder = async (id) => {
  return api.post(`order/save-order/${id}`);
};

export const SavePictureOrder = async (id) => {
  return api.post(`order/save-picture-order/${id}`);
};

export const SaveParcelOrder = async (id) => {
  return api.post(`order/save-parcel-order/${id}`);
};

export const SaveParcelInWarehouse = async (id) => {
  return api.post(`admin/order/save-parcel-in-warehouse?id=${id}`);
};

export const InitializePayment = async (id) => {
  return api.post(`order/initialize-payment/${id}`, {mode:'cors'});
};

export const EditOrdesInReview = async (id, data) => {
  return api.patch(`admin/order/update-order-status/${id}`, data);
};

export const EditOrdesInReviewStatus = async (id, data) => {
  return api.patch(`admin/order/update-order-status/${id}`, data);
};

export const GetOrdersInReview = async () => {
  return api.get(`admin/order/list-review-orders`);
};

export const GetOrdersInReviewByStatus = async (status) => {
  return api.get(`admin/order/order-by-status?status=${status}`);
};

export const GetOrdersCountByStatus = async (status) => {
  return api.get(`admin/order/count?status=${status}`);
};

export const GetOrdersInReviewById = async (id) => {
  return api.get(`admin/order/review-order/${id}`);
};

export const GetOrdersInReviewByDetails = async (id) => {
  return api.get(`admin/order/links-review-details/${id}`);
};

export const GetParcelsInReviewById = async (id) => {
  return api.get(`admin/parcel/review-parcel/${id}`);
};

export const Download = async (id) => {
  return api.get(`admin/order/export-csv/${id}`);
};

export const GetParcelsInReview = async () => {
  return api.get(`admin/parcel/list-review-parcel`);
};

export const EditParcelsInReviewById = async (id, data) => {
  return api.patch(`admin/parcel/review-parcel/${id}`, data);
};

export const TrackingId = async (id, data) => {
  return api.post(`admin/order/${id}/update-tracking`, data)
}

export const GetTrackingId = async (id) => {
  return api.get(`admin/order/tracking/${id}`);
};

export const DeleteTracking = async(id) => {
  return api.delete(`admin/order/${id}/delete-tracking`)
}
//Parcel Admin
export const GetParcelsInShipping = async () => {
  return api.get(`admin/order/list-review-request-shipping`);
};

export const GetParcelsShippingInReviewById = async (id) => {
  return api.get(`admin/order/review-request/${id}`);
};

export const EditShippingInReview = async (id, data) => {
  return api.patch(`admin/parcel/request-parcel/${id}`, data);
};

export const GetShippingInReviewByDetails = async (id) => {
  return api.get(`admin/order/parcels-in-shipping/${id}`);
};

export const TrackingIdShipping = async (id, data) => {
  return api.post(`admin/order/${id}/update-tracking-parcel`, data)
}

export const GetTrackingIdShipping = async (id) => {
  return api.get(`admin/order/tracking-parcel/${id}`);
};

export const DeleteTrackingShipping = async(id) => {
  return api.delete(`admin/order/${id}/delete-tracking`)
}

//payment confirmation

export const GetTopUp = async () => {
  return api.get(`admin/order/list-earnings`);
};

export const GetTopById = async (id) => {
  return api.get(`admin/order/topup/${id}`);
};

export const EditTopUp = async (id, data) => {
  return api.patch(`admin/order/update-fund/${id}`, data);
};

export const GetTopUpPayStack = async () => {
  return api.get(`admin/order/list-paystack`);
};

export const GetTopPaystackById = async (id) => {
  return api.get(`admin/order/ptopup/${id}`);
};

export const EditTopPaystackUp = async (id, data) => {
  return api.patch(`admin/order/update-paystack/${id}`, data);
};

//Notification

export const GetAllNotifications = async () => {
  return api.get(`admin/order/list-notifications`);
};

export const GetAllNotificationsById = async (id) => {
  return api.get(`admin/order/get-notification/${id}`);
};

export const EditGetAllNotifications = async (id, data) => {
  return api.patch(`admin/order/update-notification/${id}`, data);
};

export const CreateNotifications = async (data) => {
  return api.post(`order/new-notification`, data);
};
export const DeleteNotification = async(id) => {
  return api.delete(`admin/order/delete-notication/${id}`)
}



