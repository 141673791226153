import React, { useEffect } from "react";
import "./UnitParcelReview.css";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { getParcelsInById } from "../../../ToolKit/Features/Review/ReviewService";
import { useDispatch, useSelector } from "react-redux";
import { initializePayment } from "../../../ToolKit/Features/Review/ReviewService";
import { saveParcelInWarehouse } from "../../../ToolKit/Features/Review/ReviewService";

const UnitParcelReview = () => {
  const { id } = useParams();
  const review = useSelector((state) => state.review.parcelsReview.find((p) => p.id === Number(id)));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.util);

  useEffect(() => {
    localStorage.setItem("reviewId", id);
    dispatch(getParcelsInById(id));
  }, [dispatch, id]);

  const makePayment = () => {
    dispatch(initializePayment(review.id));
  };

  const saveToWareHouse = () => {
    dispatch(saveParcelInWarehouse(review.id));
  }

  if (!review) {
    return <div>Loading....</div>;
  }

  return (
    <div class="userlist-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div class="card">
          <div class="card-header">
            <h2>Parcel Details</h2>
            <span>Order ID: #{review?.id}</span>
          </div>
          <div class="card-body">
            <div class="order-item">
              <h3>Title: Product Name</h3>
              <p>Parcel Number: {review?.parcelNumber}</p>
              <p>Payment Status: Pending</p>
              <p>Amount: </p>
            </div>
            <div class="order-details-btn-section">
              <button
              class="order-details-btn"
              >
                View
              </button>
              <button onClick={saveToWareHouse} class="order-details-btn">
                Send to Warehouse
              </button>
              <button
                onClick={() => {
                  navigate(`/edit-parcel-review/${review?.id}`);
                }}
                class="order-details-btn"
              >
                Edit
              </button>
              <button onClick={makePayment} class="payment-btn">
                Pay
              </button>
          
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UnitParcelReview;
