import api from "../../ApiRequest/axiosRequest";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";



export const addLinks = createAsyncThunk("linkorders/id/send-links", async (payload) => {
    
    
    try {
      const response = await api.post(
        `linkorders/${payload.id}/send-links`,
        payload
      );
      if (response) {
        toast.success(response?.data?.message);
        window.location.href = `/list-links/${payload.id}`;
        return response.data;
      }
    } catch (error) {
      Errorhandler(error);
    }
    

  });

  export const fetchAllLinksById = createAsyncThunk(
    "linkorders/links-by-user",
    async (id, { rejectWithValue }) => {
      let timeoutId;
  
      const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 1000);
      });
  
      try {
        const response = await Promise.race([
          api.get(`linkorders/links-by-user/${id}`),
          timeoutPromise,
        ]);
  
        clearTimeout(timeoutId);
  
        if (response) {
          toast.success(response?.data?.message);
          return response.data;
        }
      } catch (error) {
        //Errorhandler(error);
        return rejectWithValue(error.message);
      }
    }
  );


  export const deleteLink = createAsyncThunk("linkorders/delete", async (id) => {

    
    try {
      const response = await api.delete(`linkorders/${id}`);
      if (response) {
        toast.success(response?.data?.message);
        return id;
      }
    } catch (error) {
      Errorhandler(error);
    }
    
  });


  export const updateLinks = createAsyncThunk("linkorders/update", async (payload) => {
    
    
    try {
      const response = await api.patch(
        `linkorders/${payload.id}`,
        payload
      );
      if (response) {
        toast.success(response?.data?.message);
        window.location.href = "/list-orders"
        return response.data;
      }
    } catch (error) {
      Errorhandler(error);
    }
    

  });


  
  


  const initialState = {
    addLinksData: [],
    loading: "idle",
  };



  const addLinkSlice = createSlice({
    name: "addLinks",
    initialState,
    reducers: {},

    extraReducers: (builder) => {


      builder.addCase(fetchAllLinksById.pending, (state, action) => {
        state.loading = "pending";
      });

      builder.addCase(fetchAllLinksById.fulfilled, (state, action) => {
        state.loading = "idle";
        state.addLinksData = action.payload;
      
      });

      

        builder.addCase(addLinks.pending, (state) => {
            state.loading = "pending";
          });
          builder.addCase(addLinks.fulfilled, (state, action) => {
            state.loading = "idle";
            state.addLinksData.unshift(action.payload);
          });

          

          builder.addCase(deleteLink.pending, (state) => {
            state.loading = "pending";
          });
          
          builder.addCase(deleteLink.fulfilled, (state, action) => {
            state.loading = "idle";
            state.addLinksData = state.addLinksData.filter((_) => _.id !== action.payload);
          });

          

          builder.addCase(updateLinks.pending, (state) => {
            state.loading = "pending";
          });

          builder.addCase(updateLinks.fulfilled, (state, action) => {
            state.loading = "idle";
            state.addLinksData = state.addLinksData.filter((_) => _.id !== action.payload.id);
            state.addLinksData.unshift(action.payload);
          });

          


   
    }});

    export const getAllLinks = (state) => state.addLink.addLinksData;
    export const getLoading = (state) => state.addLink.loading;
    export const getLinksId = (id) => {
        return (state) => state.addLink.addLinksData.filter((_) => _.id === id)[0];
      };

    export const getLinksById = (state, id) =>
  state.addLink.addLinksData.find(links => links.id === id);

      export default addLinkSlice.reducer;

