import {
  CreateOrderApi,
  getOrderApi,
  editOrderApi,
  getOrderDetailApi,
  CreateInitialPictureOrderApi,
  GetPictureOrderListApi,
  DeletePictureOrderApi,
  GetPictureOrderListDetailApi,
  DeleteSinglePictureOrderApi,
  EditPictureOrderApi,
  GetPictureOrderListDetail_IdApi,
  EditPictureOrderListDetail_IdApi,
  GetPictureOrderListById,
} from "../../ApiRequest/Api/Order";
import {
  setCreateOrder,
  setOrders,
  setEditOrder,
  setOrderDetails,
  setPictureOrderList,
  setPictureOrderListDetail,
  setPictureOrderListDetailId,
  setPictureOrderListById,
} from "./OrderSlice";
import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";
import { dispatch } from "../../Store";
import { setLoading } from "../../utils/UtilSlice";

export const createInitialPictureOrder =
  (data, setTrackResponse) => async () => {
    dispatch(setLoading(true));
    try {
      const res = await CreateInitialPictureOrderApi(data);
      toast.success(res?.data?.message);
      if (res.status === 200) setTrackResponse(true);
      dispatch(setLoading(false));
    } catch (error) {
      Errorhandler(error);
      dispatch(setLoading(false));
    }
  };

export const getPictureOrderList = (pageNo, pageSize) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetPictureOrderListApi(pageNo, pageSize);
    dispatch(setLoading(false));
    dispatch(setPictureOrderList(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const deletePictureOrder = (id) => async () => {
  try {
    const res = await DeletePictureOrderApi(id);
    if (res) {
      toast.success(res?.data?.message);
      dispatch(getPictureOrderList());
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const deleteSinglePictureOrder =
  (id, delete_id, pageNo, pageSize) => async () => {
    try {
      const res = await DeleteSinglePictureOrderApi(delete_id);
      if (res) {
        toast.success(res?.data?.message);
        dispatch(getPictureOrderListDetail(id, pageNo, pageSize));
      }
    } catch (error) {
      Errorhandler(error);
    }
  };

export const getPictureOrderListDetail = (id, pageNo, pageSize) => async () => {
  try {
    const res = await GetPictureOrderListDetailApi(id, pageNo, pageSize);
    if (res) dispatch(setPictureOrderListDetail(res?.data));
  } catch (error) {
    Errorhandler(error);
  }
};

export const editPictureOrder = (id, data, pageNo, pageSize) => async () => {
  try {
    const res = await EditPictureOrderApi(id, data);
    if (res) {
      toast.success(res.data.message);
      dispatch(getPictureOrderList(pageNo, pageSize));
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const createOrder = (data, setTrackRes, trackRes) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await CreateOrderApi(data);

    if (res) {
      toast.success(res?.data?.message);
      setCreateOrder(res?.data);
      if (res.status === 200) setTrackRes(!trackRes);
    }
    dispatch(setLoading(false));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getPictureOrderListDetail_Id = (id) => async () => {
  try {
    const res = await GetPictureOrderListDetail_IdApi(id);
    dispatch(setPictureOrderListDetailId(res.data));
    console.log(res);
  } catch (error) {
    Errorhandler(error);
  }
};

export const getPictureOrderListById = (id) => async () => {
  try {
    const res = await GetPictureOrderListById(id);
    dispatch(setPictureOrderListById(res.data));
    console.log(res);
  } catch (error) {
    Errorhandler(error);
  }
};

export const editPictureOrderListDetail_Id = (id, data) => async () => {
  try {
    const res = await EditPictureOrderListDetail_IdApi(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = "/picture-order/order-list";
    }
  } catch (error) {
    Errorhandler(error);
  }
};

// <!------>

export const getOrder = (pageNo, pageSize) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await getOrderApi(pageNo, pageSize);
    dispatch(setOrders(res?.data));
    dispatch(setLoading(false));
    return res;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch(setLoading(false));
    Errorhandler(error);
  }
};

export const getOrderDetail = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await getOrderDetailApi(id);
    dispatch(setOrderDetails(res?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    Errorhandler(error);
  }
};

export const editOrder = (id, data, pageNo, pageSize) => async () => {
  try {
    const res = await editOrderApi(id, data);
    toast.success(res?.data?.message);
    dispatch(setEditOrder(res?.data));
    dispatch(getOrder(pageNo, pageSize));
  } catch (error) {
    Errorhandler(error);
  }
};
