import React from "react";
import styles from "./Tracking.module.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { trackingShippingId } from "../../ToolKit/Features/Review/ReviewService";
import { useParams } from "react-router-dom";

const TrackingParcel = () => {
  const { id } = useParams();

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      notes: "",
    },
  });

  const disptach = useDispatch();


  const createTrack = (data) => {
    let payload = {
      id: Number(id),
      notes: data.notes,
    };
    disptach(trackingShippingId(id,payload))
      .unwrap()
      .then(() => {
        console.log("Success");
      });
  };

  return (
    <div className={styles.userlist_container}>
       <div className={styles.content_container}>
       <div className={styles.container}>
          <h6>Add Tracking Update</h6>
          <form className={styles.form} onSubmit={handleSubmit(createTrack)}>
            <div>
              <label className={styles.label} htmlFor="links">Add Track Update</label>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <select  className={styles.input}
                    {...field}
                    {...register("notes", { required: "Please enter track update." })}
                  > <option value="">Select an Item</option>
                  <option value=" Payment confirmed"> Payment confirmed</option>
                  <option value="Parcel is being checked and consolidated">Parcel is being checked and consolidated</option>
                  <option value=" Parcel has been sent to your selected logistics partner"> Parcel has been sent to your selected logistics partner</option>
                  <option value="Parcel has been sent to international airport">Parcel has been sent to international airport</option>
                  <option value=" Parcel has been shipped out of china and will arrive in 7-9 days for normal goods. Parcel will arrive in 14-21 days if it contains restricted items and 3 months if it has been shipped by sea. The shipping receipt will be sent to your WhatsApp within 2 days. Please look out for it in your inbox. Thank you"> Parcel has been shipped out of china and will arrive in 7-9 days for normal goods. Parcel will arrive in 14-21 days if it contains restricted items and 3 months if it has been shipped by sea. The shipping receipt will be sent to your WhatsApp within 2 days. Please look out for it in your inbox. Thank you</option>
                  <option value="Parcel has arrived to Lagos airport and is currently under clearance">Parcel has arrived to Lagos airport and is currently under clearance</option>
                  <option value="Parcel has arrived to warehouse, please be on the lookout for a pick up message to your inbox"> Parcel has arrived to warehouse, please be on the lookout for a pick up message to your inbox</option>
                
                  </select>
                )}
              />
            </div>


            <div className={styles.buttons_container}>
              <button
                type="submit"
                className={styles.button}
        
              >
                Add Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrackingParcel;
