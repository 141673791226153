import api from "../axiosRequest";

export const CreateParcel = async (data) => {
  return api.post(`parcel/send-parcelOrder`, data);
};

export const EditParcelOrder = async (id, data) => {
  return api.patch(`parcel/${id}`, data);
};

export const GetParcelOrders = async () => {
  return api.get(`admin/parcel/list-parcels`);
};

export const GetParcelById = async (id) => {
  return api.get(`admin/parcel/${id}`);
};

export const DeleteParcelById = async (id) => {
  return api.delete(`admin/parcel/${id}`);
};
