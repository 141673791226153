import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./Features/User/UserSlice";
import orderReducer from "./Features/Order/OrderSlice";
import adminReducer from "./Features/Admin/AdminSlice";
import utilReducer from "./utils/UtilSlice";
import linkOrderReducer from "./Features/LinkOrder/linkOrderSlice";
import addLinkReducer from "./Features/LinkOrder/addLinkSlice";
import customOrderReducer from "./Features/CustomOrder/customOrderSlice";

import reviewReducer from "./Features/Review/ReviewSlice";
import referralReducer from "./Features/Referral/ReferralSlice";
import parcelReducer from "./Features/Parcel/ParcelSlice"
import auserReducer from "./Features/AUsers/AUsersSlice"

import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const rootReducer = combineReducers({
  user: userReducer,
  userOrder: orderReducer,
  adminOrder: adminReducer,
  util: utilReducer,
  link: linkOrderReducer,
  addLink: addLinkReducer,
  custom: customOrderReducer,
  review: reviewReducer,
  referral: referralReducer,
  parcel: parcelReducer,
  users: auserReducer,
  //parcel: persistedReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    "user",
    "adminOrder",
    "link",
    "addParcel",
    "custom",
    "addLink",
    "util",
  ],
  //whitelist: ["oneParcel"]
  // blacklist: ["user", "userOrder", "adminOrder","util", "link", "addLink", "custom", "parcel","addParcel"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  //middleware: [thunk],
  //middleware:  (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),

  devTools: true,
});

export const dispatch = store.dispatch;
export default store;

export let persistor = persistStore(store);
