import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { NavLink } from "react-router-dom";





const Sidebar = ({ children }) => {
 

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
  };

  return (
    <div>
      <div 
     
     className={`${styles.sidenav} ${sidebarOpen ? styles.open : ''}`}>
        <div>Checkit Procurement</div>
        
          <div className={styles.head}>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : `${styles.link} `
              }
              onClick={closeSidebar}
            >
             
              <p>Dashboard</p>
            </NavLink>

           {/* 

            <NavLink
              to="/consolidation"
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : `${styles.link} `
              }
              onClick={closeSidebar}
            >
              
              <p>Parcel in Review</p>
            </NavLink>
            */}

            

           

            <NavLink
              to="/account"
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : `${styles.link} `
              }
              onClick={closeSidebar}
            >
             
              <p>Admin Account</p>
            </NavLink>
          </div>
   
      </div>
      <div  className={styles.menu_bar}>
        {" "}
      
        <span  onClick={toggleSidebar}>☰</span>{" "}
      </div>
      <div className={`${styles.main} ${sidebarOpen ? styles.open : ''}`}>{children}</div>
    </div>

   
  );
};

export default Sidebar;
