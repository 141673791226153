import {React, useEffect, useState }from "react";
import styles from "./OrderinReview.module.css";
import {getTopUpPaystack} from "../../../ToolKit/Features/Review/ReviewService";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const ITEMS_PER_PAGE = 10;

const TopupPay = () => {

  const dispatch = useDispatch();
  const {isLoading} = useSelector((state) => state.util);
  const reviewLists = useSelector((state) => state?.review?.paystack)
  const [reviewList, setReviewList] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [localLoading, setLocalLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
   
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        //dispatch(getOrderInreviewByStatus(event.target.value));
      }, 500)
    );
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getTopUpPaystack());
      setLocalLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading) {
      setReviewList(reviewLists);
      setTotalPages(Math.ceil(reviewLists.length / ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }, [localLoading, reviewLists]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  if (localLoading) {
    return <Loader />;
  }


  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : reviewList?.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h2>Procurement</h2>
          <input
            type="text"
            placeholder="Search by trackcode..."
            value={searchInput}
            onChange={handleSearchInput}
          />
           <table className={styles.user_table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {reviewList.slice(startIdx, endIdx).map((review, idx) => (
                <tr>
                  <td data-label="ID">{review?.id}</td>
                  <td data-label="Date">{review?.timestamp}</td>
                  <td data-label="Amount">{review?.earnings}</td>
                  <td data-label="Status">{review?.depositStatus}</td>
                  <td
                    onClick={() => {
                      navigate(`/edit-paystack/${review?.id}`);
                    }}
                    data-label="Update"
                  >
                    <button className={styles.edit_btn}>Update</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          <div className={styles.pagination}>
            <button className={styles.page_button} onClick={handlePrevClick} disabled={currentPage === 1}>
              &#8592;
            </button>
            <span className={styles.page_number}>
              {currentPage} / {totalPages}
            </span>
            <button className={styles.page_button} onClick={handleNextClick} disabled={currentPage === totalPages}>
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopupPay;
