import api from "../../ApiRequest/axiosRequest";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";


export const fetchAllCustomOrders = createAsyncThunk("/api/customorders/list", async () => {
    const response = await api.get("customorders/list-linkorders?pageNo=0&pageSize=500");
    return response.data;
  });


  export const fetchOneCustomOrder = createAsyncThunk("/api/customorders/view", async (id) => {
    const response = await api.get(`customorders/${id}`);
    return response.data;
  });




  export const deleteCustomOrder = createAsyncThunk("/api/customorders/delete", async (id) => {

    
    try {
      const response = await api.delete(`customorders/${id}`);
      if (response) {
        toast.success(response?.data?.message);
        return id;
      }
    } catch (error) {
      Errorhandler(error);
    }
    
  });

  


  // export const createCustomOrdersReq = createAsyncThunk('customorders/create', async (formData) => {
  //   try{
  //   const response = await api.post("customorders/req-customorder", {
  //     body: formData,
  //   });
  //   if(response){
  //     toast.success(response?.data?.message);
  //   const data = await response.json();
  //   window.location.href = "/list-custom-orders";
  //   return data;
  //   }
  // } catch(error){
  //   Errorhandler(error);
  // }
  // });




  const initialState = {
    customOrderData: [],
    loading: "idle",
    custom: '',
    specification: '',
    quantity: '',
    budget: null,
    imageUrl: null,
    
  };


  const customOrderSlice = createSlice({
    name: "customOrders",
    initialState,
    reducers: {},
    

    extraReducers: (builder) => {

        builder.addCase(fetchAllCustomOrders.pending, (state, action) => {
            state.loading = "pending";
          });

          builder.addCase(fetchAllCustomOrders.fulfilled, (state, action) => {
            state.loading = "idle";
            state.customOrderData = action.payload;
          });

          // builder.addCase(createCustomOrdersReq.pending, (state, action) => {
          //   state.loading = "pending";
          // });
          // builder.addCase(createCustomOrdersReq.fulfilled, (state, action) => {
          //   state.loading = "idle";
          //   //state.customOrderData.unshift(action.payload);
          // });
          
          builder.addCase(deleteCustomOrder.pending, (state) => {
            state.loading = "pending";
          });
          builder.addCase(deleteCustomOrder.fulfilled, (state, action) => {
            state.loading = "idle";
            state.customOrderData = state.customOrderData.filter((_) => _.id !== action.payload);
          });

          builder.addCase(fetchOneCustomOrder.pending, (state, action) => {
            state.loading = "pending";
          });
      
          builder.addCase(fetchOneCustomOrder.fulfilled, (state, action) => {
            state.loading = "idle";
          });
      

        }});

    export const getAllCustomOrders = (state) => state.custom.customOrderData;
    export const getLoading = (state) => state.custom.loading;
    export const getCustomOrderById = (id) => {
      return (state) => state.custom.customOrderData.filter((_) => _.id === id)[0];
    };

    export const getCustomerId = (state, id) =>
    state.custom.customOrderData.find(custom => custom.id === id);

    export default customOrderSlice.reducer; 