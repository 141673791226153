import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editTopUp, getTopById  } from "../../ToolKit/Features/Review/ReviewService";
import styles from "./EditMyParcel.module.css"

const EditPaymentStatus = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const exchangeData = useSelector((state) => state?.referral?.exchangeId)
  const [depositStatus, setDepositStatus] = useState("");



  useEffect(() => {
    if (exchangeData) {
    
      setDepositStatus(exchangeData?.depositStatus);
  
    }
  }, [exchangeData]); 

  const updateParcel = (e) => {
    e.preventDefault();
    let payload = {
      depositStatus: depositStatus,
    };
    dispatch(editTopUp(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getTopById(id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Edit Payment Status</h6>
          <form className={styles.form} onSubmit={updateParcel}>
            
            <label className={styles.label} htmlFor="quantity">
              Deposit Status
            </label>
            <select
              className={styles.input}
              onChange={(e) => setDepositStatus(e.target.value)}
              value={depositStatus}
              name="inspection"
              id="inspect"
            >
              <option value="">--select--</option>
              <option value="pending">pending</option>
              <option value="paid">Paid</option>
        
            </select>
           
            

            <br></br>
            <div className={styles.buttons_container}>
            <button className={styles.button}>Update Payment Status</button>
            </div>

           
          </form>
        </div>
      </div>
    </div>
  );
  };
export default EditPaymentStatus;