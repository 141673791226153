import React, { useEffect, useState } from "react";
import styles from "./ParcelInReview.module.css";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getParcelsInreview,
  saveParcelInWarehouse,
} from "../../../ToolKit/Features/Review/ReviewService";

const ITEMS_PER_PAGE = 10;

const ParcelInReview = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.util);
  const parcelLists = useSelector((state) => state?.review?.parcelsReview);
  const [parcelList, setParcelList] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [localLoading, setLocalLoading] = useState(true);
  const usernamex = Object.keys(parcelList);

  const [searchInput, setSearchInput] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedParcels, setSelectedParcels] = useState({});

  const handleParcelSelect = (userId, parcelId) => {
    const updatedSelectedParcels = { ...selectedParcels };

    if (!updatedSelectedParcels[userId]) {
      updatedSelectedParcels[userId] = new Set();
    }

    if (updatedSelectedParcels[userId].has(parcelId)) {
      updatedSelectedParcels[userId].delete(parcelId);
    } else {
      updatedSelectedParcels[userId].add(parcelId);
    }

    setSelectedParcels(updatedSelectedParcels);
  };

  const handleSelectAll = (userId) => {
    const userParcels = parcelList[userId];
    const selected = selectedParcels[userId] || new Set();

    if (selected.size === userParcels.length) {
      selectedParcels[userId] = new Set();
    } else {
      selectedParcels[userId] = new Set(userParcels.map((parcel) => parcel.id));
    }

    setSelectedParcels({ ...selectedParcels });
  };

  // const handleSubmitSelected = async () => {
  //   const selected = selectedParcels[usernamex] || new Set();
  //   if (selected.size > 0) {
  //     await saveParcelInWarehouse(Array.from(selected));
  //   }
  // };

  const handleSubmitSelected = async () => {
    let allSelectedParcels = [];
  
    Object.keys(selectedParcels).forEach((user) => {
      const selected = selectedParcels[user] || new Set();
      allSelectedParcels = allSelectedParcels.concat(Array.from(selected));
    });
  
    if (allSelectedParcels.length > 0) {
      await saveParcelInWarehouse(allSelectedParcels);
    }
  };
  

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        // dispatch(getOrderInreviewByStatus(event.target.value));
      }, 500)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getParcelsInreview());
      setLocalLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading) {
      setParcelList(parcelLists);
      setTotalPages(Math.ceil(Object.keys(parcelLists).length / ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }, [localLoading, parcelLists]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  if (localLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : Object.keys(parcelList).length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h2>Parcels</h2>
          <input
            type="text"
            placeholder="Search by trackcode..."
            value={searchInput}
            onChange={handleSearchInput}
          />
          {Object.keys(parcelList).slice(startIdx, endIdx).map((username, idx) => (
            <div key={username}>
              <h3>User: {username}</h3>
              <table className={styles.user_table}>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={() => handleSelectAll(username)}
                        checked={
                          selectedParcels[username]?.size ===
                          parcelList[username]?.length
                        }
                      />
                    </th>
                    <th>Parcel Number</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  {parcelList[username].map((parcel) => (
                    <tr key={parcel.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedParcels[username]?.has(parcel.id)}
                          onChange={() => handleParcelSelect(username, parcel.id)}
                        />
                      </td>
                      <td>{parcel.parcelNumber}</td>
                      <td>{parcel.created}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          <button
            className={styles.edit_btn}
            onClick={handleSubmitSelected}
            disabled={selectedParcels[usernamex]?.size === 0}
          >
            Confirm Availability
          </button>

          <div className={styles.pagination}>
            <button
              className={styles.page_button}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &#8592;
            </button>
            <span className={styles.page_number}>
              {currentPage} / {totalPages}
            </span>
            <button
              className={styles.page_button}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParcelInReview;
