import React from "react";
import { getCurrentUser } from "../../services/auth";
import { ShortenTextLength } from "../../components/Functions/ShortTextLength";
import { useAppContext } from "../../context/Context";
import styles from "./Account.module.css";


const Account = () => {
  const { handleLogout } = useAppContext();
  const currentUser = getCurrentUser();

  return (

    <div className={styles.container}>
    <h1 className={styles.h1}>My Account</h1>
    <div className={styles.account_info} >
      <div className={styles.row}>
        <div className={styles.label}>Username:</div>
        <div className={styles.value} >{currentUser?.username}</div>
      </div>
      <div className={styles.row} >
        <div className={styles.label}>Email:</div>
        <div className={styles.value}>{currentUser?.email}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>User ID Token:</div>
        <div className={styles.value}>{currentUser?.id}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Account Type:</div>
        <div className={styles.value}>{ShortenTextLength(currentUser?.accessToken)}</div>
      </div>
    </div>
    <div className={styles.account_actions}>
     
      <button onClick={handleLogout} >Logout</button>
    </div>
  </div>
    /*
    <div className={styles.general}>
      <div className={styles.container_content}>
        <div className={styles.user_details}>
          <div className={styles.user_bg}>
            <img src={user} alt="user" />
          </div>

          <div className={styles.details}>
            <div>
              <p>
                Username: &nbsp;
                <span>{currentUser?.username}</span>
              </p>
              <p></p>
            </div>

            <div>
              <p>
                Email Address: &nbsp;
                <span>{currentUser?.email}</span>
              </p>
            </div>

            <div>
              <p>
                User ID: &nbsp;
                <span>{currentUser?.id}</span>
              </p>
            </div>
            <div>
              <p>
                account type: &nbsp;
              </p>
                {adminAccount ? <p>Admin</p> : <p>user</p>}
            </div>
            <div>
              <p>
                Token: &nbsp;
                <span>{ShortenTextLength(currentUser?.accessToken)}</span>
              </p>
            </div>
          </div>
        </div>
        <button onClick={handleLogout}>logout</button>
      </div>
    </div>
    */
  );
};

export default Account;
