import {React, useEffect, useState } from "react";
import styles from "./Tracking.module.css";
import { useDispatch , useSelector  } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { getAllExchange } from "../../ToolKit/Features/Referral/ReferralService";
import { useNavigate } from 'react-router-dom';


const Exchange = () => {

  const {isLoading} = useSelector((state) => state.util);
  const rate = useSelector((state) => state?.referral?.exchangeAll)
  const [rates, setRates] = useState([]);

  const [localLoading, setLocalLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllExchange ());
      setLocalLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading) {
      setRates(rate);
      
    }
  }, [localLoading, rate]);


  //const { register, control, handleSubmit } = useForm({
   // defaultValues: {
   //   usd: "",
  //    yen:"",
  //  },
  //});

  //const disptach = useDispatch();
/** 
  const createTrack = (data) => {
    let payload = {
      yen: data.yen,
      usd: data.usd,
    };
    disptach(createExchange(payload))
      .unwrap()
      .then(() => {
        console.log("Success");
      });
  };
  */

  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : rates?.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h2>Exchange Rate</h2>
          
           <table className={styles.user_table}>
            <thead>
              <tr>
                
                <th>USD</th>
                <th>Yuan</th>
                <th>Edit</th>
               
              </tr>
            </thead>
            <tbody>
              {rates.map((rates, idx) => (
                <tr>
                  
                  <td data-label="USD">{rates?.usd}</td>
                  <td data-label="Yuan">{rates?.yen}</td>
                 
                  <td
                    onClick={() => {
                      navigate(`/edit-exchange/${rates?.id}`);
                    }}
                    data-label="Edit"
                  >
                    <button className={styles.edit_btn}>Edit Rate</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        
        </div>
      )}
      
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Add Tracking Update</h6>
          {/** 
          <form className={styles.form} onSubmit={handleSubmit(createTrack)}>
            <div>
              <label className={styles.label} htmlFor="links">
                Add Track Update
              </label>
              <Controller
                control={control}
                name="usd"
                render={({ field }) => (
                  <input
                    className={styles.input}
                    {...field}
                    {...register("usd", {
                      required: "Please enter track update.",
                    })}
                  />
                   
                 
                )}
              />
               <Controller
                control={control}
                name="yen"
                render={({ field }) => (
                  <input
                    className={styles.input}
                    {...field}
                    {...register("yen", {
                      required: "Please enter track update.",
                    })}
                  />
                   
                 
                )}
              />
            </div>

            <div className={styles.buttons_container}>
              <button type="submit" className={styles.button}>
                Add Update
              </button>
            </div>
          </form>
          */}
        </div>
      </div>
    </div>
  );
};

export default Exchange;
