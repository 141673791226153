import React, { useEffect } from "react";
import "./UserById.css";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { getUserById } from "../../../ToolKit/Features/AUsers/AUsersService";
import { useDispatch, useSelector } from "react-redux";


const UserById = () => {
  const { id } = useParams();
  const user = useSelector((state) =>
    state.users.ausers.find((p) => p.id === Number(id))
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.util);

  useEffect(() => {
    localStorage.setItem("reviewId", id);
    dispatch(getUserById(id));
  }, [dispatch, id]); 

  if (!user) {
    return <div>Loading....</div>;
  }

  return (
    <div class="userlist-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div class="card">
          <div class="card-header">
            <h6>User Details</h6>
            <span>User ID: #{user?.id}</span>
          </div>
          <div class="card-body">
            <div class="order-item">
             
              <p>User Name: {user?.username}</p>
              <p>User Phone Number: {user?.phonenumber}</p>
              <p>User Email: {user?.email}</p>
              <p>Amount Earn: {user?.amount}</p>
             
              
            </div>
            <div class="order-details-btn-section">
              
              <button
                onClick={() => {
                  navigate(`/users-edit/${user?.id}`);
                }}
                class="order-details-btn"
              >
                Edit
              </button>
             
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UserById;
