import { Routes, Route } from "react-router-dom";
import { Authenticated, RequireToken } from "./Authentication";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/signIn/Login";

import Account from "./pages/Account/Account";
import Dashboard from "./pages/Dashboard/Dashboard";
import Error from "./pages/Error/Error";
import Procurement from "./pages/MyOrder/Procurement/Procurement";
import OrderinReview from "./pages/MyOrder/OrderReviewList/OrderinReview";
import UnitOrderReview from "./pages/MyOrder/UnitOrderReview/UnitOrderReview";
import DetailsUnitReview from "./pages/MyOrder/DetailsUnitReview/DetailsUnitReview";
import InProgress from "./pages/MyOrder/OrderStatus/InProgress";
import Shipped from "./pages/MyOrder/OrderStatus/Shipped";
import Closed from "./pages/MyOrder/OrderStatus/Closed";
import Consolidation from "./pages/MyOrder/Consolidation/Consolidation";
import EditMyOrder from "./pages/MyOrder/EditMyOrder/EditMyOrder";
import Referral from "./pages/Referral/Referral";

import ViewOneParcel from "./pages/MyOrder/ParcelOrder/ViewOneParcel/ViewOneParcel";

import EditMyParcelOrder from "./pages/MyOrder/ParcelOrder/EditParcel/EditParcelOrder";
import ParcelInReview from "./pages/MyOrder/ParcelInReview/ParcelInReview";
import UnitParcelReview from "./pages/MyOrder/UnitParcelInReview/UnitParcelReview";
import EditMyParcel from "./pages/MyOrder/EditMyParcel/EditMyParcel";
import AllUser from "./pages/Users/AllUser/AllUser";
import UserById from "./pages/Users/UsersById/UserById";
import Tracking from "./pages/Tracking/Tracking";
import Exchange from "./pages/Tracking/Exchange";
import ViewTrackings from "./pages/Tracking/ViewTrackings/ViewTrackings";
import UnitParcelShipReview from "./pages/MyOrder/UnitParcelInReview/UnitRequestShipping";
import RequestShipping from "./pages/MyOrder/ParcelInReview/RequestShipping";
import EditMyParcelShipping from "./pages/MyOrder/EditMyParcel/EditShipping";
import TrackingParcel from "./pages/Tracking/TrackingParcel";
import ViewTrackingsShipping from "./pages/Tracking/ViewTrackings/ViewTrackingsParcel";
import TopupPay from "./pages/MyOrder/Topup/TopupPay";
import Topup from "./pages/MyOrder/Topup/Topup";
import EditExchange from "./pages/Tracking/EditExchange";
import EditPaymentStatus from "./pages/Tracking/EditPaymentStatus";
import EditPayStackStatus from "./pages/Tracking/EditPayStackStatus";
import EditUser from "./pages/MyOrder/EditMyParcel/EditUser";

function App() {
  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        {/* protected from visiting when authenticated */}
        <Route element={<Authenticated />}>
          <Route path="/" element={<Login />} />
        </Route>

        {/* protected route */}
        <Route element={<RequireToken />}>
          <Route path="/profile" element={<Dashboard />} />

          <Route path="/account" element={<Account />} />

          <Route path="/referrals" element={<Referral />}></Route>

          <Route path="/my-order" element={<Procurement />}></Route>
          <Route path="/all-order-review" element={<OrderinReview />}></Route>
          <Route
            path="/unit-order-review/:id"
            element={<UnitOrderReview />}
          ></Route>
          <Route path="/update-review/:id" element={<EditMyOrder />}></Route>
          <Route
            path="/unit-details/:id"
            element={<DetailsUnitReview />}
          ></Route>
          <Route path="/unit-parcel/:id" element={<ViewOneParcel />}></Route>
          <Route
            path="/updateorder-parcel/:id"
            element={<EditMyParcelOrder />}
          ></Route>
           <Route
            path="/view-track/:id"
            element={<ViewTrackings />}
          ></Route>
           <Route
            path="/edit-paystack/:id"
            element={<EditPayStackStatus />}
          ></Route>
          <Route
            path="/edit-payment-status/:id"
            element={<EditPaymentStatus />}
          ></Route>

          <Route
            path="/view-track-shipping/:id"
            element={<ViewTrackingsShipping />}
          ></Route>
           <Route
            path="/create-exchange"
            element={<Exchange />}
          ></Route>
          <Route
            path="/unit-parcel-shipping/:id"
            element={<UnitParcelShipReview />}
          ></Route>
          <Route
            path="/edit-parcel-shipping/:id"
            element={<EditMyParcelShipping />}
          ></Route>
           <Route
            path="/edit-exchange/:id"
            element={<EditExchange />}
          ></Route>
           <Route
            path="/paystack"
            element={<TopupPay />}
          ></Route>
           <Route
            path="/bank"
            element={<Topup />}
          ></Route>
            <Route
            path="/shipping-details-parcel/:id"
            element={<DetailsUnitReview />}
          ></Route>
           <Route
            path="/in-shipping"
            element={<RequestShipping />}
          ></Route>
          <Route
            path="/tracking/:id"
            element={<Tracking />}
          ></Route>
           <Route
            path="/tracking-parcel/:id"
            element={<TrackingParcel />}
          ></Route>
          <Route path="/in-progress" element={<InProgress />}></Route>
          <Route path="/users" element={<AllUser />}></Route>
          <Route path="/users/:id" element={<UserById />}></Route>
          <Route path="/users-edit/:id" element={<EditUser />}></Route>
          <Route path="/ship" element={<Shipped />}></Route>
          <Route path="/closed" element={<Closed />}></Route>
          <Route path="/consolidation" element={<Consolidation />}></Route>
          <Route path="/list-of-parcels" element={<ParcelInReview />} />

          <Route path="/list-one-parcels/:id" element={<UnitParcelReview />} />
          <Route path="/edit-parcel-review/:id" element={<EditMyParcel />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
