import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getParcelsShippingInreviewById,
  editShippingInReview,
} from "../../../ToolKit/Features/Review/ReviewService";
import styles from "./EditMyParcel.module.css";

const EditMyParcelShipping = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParcelsShippingInreviewById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const parcelData = useSelector((state) => state.review.shippingById);

  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [invoice, setInvoice] = useState("");

  useEffect(() => {
    if (parcelData) {
      setPrice(parcelData?.totalPrice);
      setStatus(parcelData?.status);
      setInvoice(parcelData?.invoice);
      setPaymentStatus(parcelData?.paymentStatus);
    }
  }, [parcelData]);

  const updateParcel = (e) => {
    e.preventDefault();
    let payload = {
      totalPrice: price,
      status: status,
      invoice: invoice,
      paymentStatus: paymentStatus,
    };
    dispatch(editShippingInReview(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getParcelsShippingInreviewById(id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Add Shipping Details</h6>
          <form className={styles.form} onSubmit={updateParcel}>
            <label className={styles.label} htmlFor="quantity">
              Price
            </label>
            <input
              className={styles.input}
              onChange={(e) => setPrice(e.target.value)}
              value={price}
            />

            <label className={styles.label} htmlFor="quantity">
              Status
            </label>
            <select
              className={styles.input}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              name="inspection"
              id="inspect"
            >
              <option value="">--select--</option>
              <option value="Shipped">Shipped</option>
              <option value="Warehouse">Warehouse</option>
            </select>
            

            <label className={styles.label} htmlFor="quantity">
              Payment Status
            </label>
            <select
              className={styles.input}
              onChange={(e) => setPaymentStatus(e.target.value)}
              value={paymentStatus}
              name="inspection"
              id="inspect"
            >
              <option value="">--select--</option>
              <option value="not paid">not paid</option>
              <option value="payment complete">payment complete</option>
            </select>
          
            <label className={styles.label} htmlFor="quantity">
              Invoice
            </label>
            <input
              className={styles.input}
              onChange={(e) => setInvoice(e.target.value)}
              value={invoice}
            />

            <br></br>
            <div className={styles.buttons_container}>
              <button className={styles.button}>Update Parcel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditMyParcelShipping;
