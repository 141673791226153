import React, { useEffect } from "react";
import "./UnitOrderReview.css";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { getOrderInreviewById } from "../../../ToolKit/Features/Review/ReviewService";
import { useDispatch, useSelector } from "react-redux";
import { download } from "../../../ToolKit/Features/Review/ReviewService";

const UnitOrderReview = () => {
  const { id } = useParams();
  const review = useSelector((state) =>
    state.review.reviews.find((p) => p.id === Number(id))
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.util);

  useEffect(() => {
    localStorage.setItem("reviewId", id);
    dispatch(getOrderInreviewById(id));
  }, [dispatch, id]); 

  const downloadLink = () => {
    dispatch(download(review.id));
  };

  if (!review) {
    return <div>Loading....</div>;
  }

  return (
    <div class="userlist-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div class="card">
          <div class="card-header">
            <h2>Order Details</h2>
            <span>Order ID: #{review?.id}</span>
          </div>
          <div class="card-body">
            <div class="order-item">
              <h3>Title: Product Name</h3>
              <p>User Name: {review?.userName}</p>
              <p>User Phone Number: {review?.number}</p>
              <p>User Email: {review?.email}</p>
              <p>Order Status: {review?.orderStatus}</p>
              <p>Payment Status: {review?.paymentStatus}</p>
              <p>Amount: {review?.totalPrice}</p>
              
            </div>
            <div class="order-details-btn-section">
              <button
                onClick={() => {
                  navigate(`/unit-details/${review?.id}`);
                }}
                class="order-details-btn"
              >
                View Summary
              </button>
             {/** <button
                onClick={() => {
                  navigate(`/tracking/${review?.id}`);
                }}
                class="order-details-btn"
              >
                Add Tracking Update
              </button>
              */} 
              <button
                onClick={() => {
                  navigate(`/view-track/${review?.id}`);
                }}
                class="order-details-btn"
              >
                Tracking
              </button>
              <button
                onClick={() => {
                  navigate(`/update-review/${review?.id}`);
                }}
                class="order-details-btn"
              >
                Review and Approve Order
              </button>
              <button onClick={downloadLink} class="payment-btn">
                Download Links
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UnitOrderReview;
