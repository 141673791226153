import { toast } from "react-toastify";

const Errorhandler = (error) => {
  console.log(error);

  if (error.response) {
    toast.error(error.response.data.message);
  } else {
    toast.error("Unknown Error");
  }
};

export default Errorhandler;
