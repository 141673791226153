import api from "../axiosRequest";

export const ReferralSystem = async (code) => {
  return api.get(`referral/user/?referralCode=${code}`);
};


export const CreateExchange = async (data) => {
  return api.post(`exchange/add-exchange`, data);
};

export const EditExchange = async (id, data) => {
  return api.patch(`exchange/exchange/${id}`, data);
};

export const GetAllExchange = async () => {
  return api.get(`exchange/list-earnings`);
};

export const GetExchangeById = async (id, data) => {
  return api.get(`exchange/exchange/${id}`, data);
};



