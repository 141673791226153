import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOrderInreviewById,
  editOrderInReview,
} from "../../../ToolKit/Features/Review/ReviewService";
import styles from "./EditMyorder.module.css";

const EditMyOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderInreviewById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const reviewData = useSelector((state) =>
    state.review.reviews.find((p) => p.id === Number(id))
  );

  const [price, setPrice] = useState(null);
  const [status, setStatus] = useState("");
  const [invoice, setInvoice] = useState(null);
  const [track, setTrack] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    if (reviewData) {
      setPrice(reviewData?.totalPrice);
      setStatus(reviewData?.orderStatus);
      setInvoice(reviewData?.invoice);
      setTrack(reviewData?.trackingUpdate);
      setPaymentStatus(reviewData?.paymentStatus);
    }
  }, [reviewData]); // add reviewData as a dependency

  const updateReview = (e) => {
    e.preventDefault();
    let payload = {
      totalPrice: price,
      orderStatus: status,
      invoice: invoice,
      trackingUpdate: track,
      paymentStatus: paymentStatus,
    };
    dispatch(editOrderInReview(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getOrderInreviewById(id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Update Order in Review</h6>
          <form className={styles.form} onSubmit={updateReview}>
            <label className={styles.label} htmlFor="specification">
              Price
            </label>
            {/* use value attribute and state variable */}
            <input
              className={styles.input}
              onChange={(e) => setPrice(e.target.valueAsNumber)}
              value={price}
              type="number"
            />

            <label className={styles.label} htmlFor="orderstatus">
              Order Status
            </label>

            <select  className={styles.input} onChange={(e) => setStatus(e.target.value)} value={status}>
              <option value="">Select an Item</option>
              <option value="Order in Review">Order in Review</option>
              <option value="Order in Progress">Order in Progress</option>
              <option value="Payment Pending">Payment Pending</option>
              <option value="shipped">Shipped</option>
              <option value="closed">Closed</option>
            </select>

            <label className={styles.label} htmlFor="quantity">Invoice</label>
            <input
              onChange={(e) => setInvoice(e.target.value)}
              value={invoice}
              className={styles.input}
            />
             {/** 
            <label className={styles.label} htmlFor="payment status">Payment Status</label>

            <select
              onChange={(e) => setPaymentStatus(e.target.value)}
              value={paymentStatus}
              className={styles.input}
            >
              <option value="">Select an Item</option>
              <option value="payment pending">Payment Pending</option>
              <option value="payment incomplete">Payment Incomplete</option>
              <option value="payment complete">Payment Complete</option>
            </select>
            */}
            

            <br></br>
           {/**
            <label className={styles.label} htmlFor="quantity">Track Update</label>
            <input
              onChange={(e) => setTrack(e.target.value)}
              value={track}
              type="text"
              className={styles.input}
            />
             */}
            <div className={styles.buttons_container}>
            <button className={styles.button} type="submit">Update Order</button>
            </div>

            
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditMyOrder;
