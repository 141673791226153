import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createOrder: null,
  orders: null,
  editOrder: null,
  orderDetail: null,
  pictureOrderList: null,
  pictureOrderListDetail: null,
  pictureOrderListDetailId: null,
  pictureOrderListById:null,

};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCreateOrder: (state, action) => {
      state.createOrder = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setEditOrder: (state, action) => {
      state.editOrder = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetail = action.payload;
    },
    setPictureOrderList: (state, action) => {
      state.pictureOrderList = action.payload;
    },
    setPictureOrderListDetail: (state, action) => {
      state.pictureOrderListDetail = action.payload;
    },
    setPictureOrderListDetailId: (state, action) => {
      state.pictureOrderListDetailId = action.payload;
    },
    setPictureOrderListById: (state, action) => {
      state.pictureOrderListById = action.payload;
    },
  },
});

export const {
  setCreateOrder,
  setOrders,
  setEditOrder,
  setOrderDetails,
  setPictureOrderList,
  setPictureOrderListDetail,
  setPictureOrderListDetailId,
  setPictureOrderListById,
} = orderSlice.actions;

export default orderSlice.reducer;
