import { ReferralSystem, GetAllExchange, CreateExchange, EditExchange, GetExchangeById} from "../../ApiRequest/Api/Referral";

import { setReferralStatus, setExchangeAll, setExchangeId} from "./ReferralSlice";

import Errorhandler from "../../ApiRequest/Errorhandler";
import { dispatch } from "../../Store";
import { setLoading } from "../../utils/UtilSlice";

import { toast } from "react-toastify";


export const getReferralSystem = (status) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await ReferralSystem(status);
    dispatch(setLoading(false));
    dispatch(setReferralStatus(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};


export const getAllExchange = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetAllExchange();
    if (res?.data) {
      dispatch(setExchangeAll(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};


export const getExchangeById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetExchangeById(id);
    dispatch(setLoading(false));
    dispatch(setExchangeId(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};



export const editExchange = (id, data) => async () => {
  try {
    const res = await EditExchange(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = `/create-exchange`
      dispatch(getAllExchange);
    }
  } catch (error) {
    Errorhandler(error);
  }
};


export const createExchange = (data,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await CreateExchange (data);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};