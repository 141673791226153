import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate } from "react-router-dom";
import { getTrackingIdShipping, deleteTrackingShipping } from "../../../ToolKit/Features/Review/ReviewService";
import styles from "./ViewTrackings.module.css";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfrimation";
import Loader from "../../../components/Loader/Loader";

const ITEMS_PER_PAGE = 10;
const ViewTrackingsShipping = () => {
  const { id } = useParams();
  const tracks = useSelector((state) => state?.review.trackship);
  const [track, setTrack] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemToDeleteId, setItemToDeleteId] = useState();
  const [showModal, setShowModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const { isLoading } = useSelector((state) => state.util);
  const [totalPages, setTotalPages] = useState(1);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await  dispatch(getTrackingIdShipping(id));
      setLocalLoading(false);
    };
    fetchData();
   
  }, [dispatch, id]);

  useEffect(() => {
    if(!localLoading) {
    setTrack(tracks);
    setTotalPages(Math.ceil(tracks.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  }
  }, [localLoading, tracks]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  const openDeleteModalHandler = (id) => {
    setShowModal(true);
    setItemToDeleteId(id);
  };

  const hideDeleteModalHandler = () => {
    setShowModal(false);
    setItemToDeleteId(0);
  };

  const confirmDeleteModalHandler = () => {
    dispatch(deleteTrackingShipping(itemToDeleteId))
      .unwrap()
      .then(() => {
        setShowModal(false);
        setItemToDeleteId(0);
      });
  };

  if (localLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.userlist_container}>
        {isLoading ? (
          <Loader />
        ) : track.length === 0 ? (
          <p>Nothing to Show</p>
        ) : (
          <div>
            <h2>Track Update</h2>
            <table className={styles.user_table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Note</th>
                <th>Edit</th>
                <th>Delete</th>
                
              </tr>
            </thead>
            <tbody>
              {track.slice(startIdx, endIdx).map((track, idx) => (
                <tr>
                  <td data-label="Date">{track.created}</td>
                  <td data-label="Note">{track.notes}</td>
                 
                  <td
                    onClick={() => {
                      navigate(`/update-links/${track.id}`);
                    }}
                    data-label="Edit"
                  >
                    <button className={styles.edit_btn}>Edit</button>
                  </td>
                  <td
                    onClick={() => {
                      openDeleteModalHandler(track.id);
                    }}
                    data-label="Delete"
                  >
                    <button className={styles.delete_btn}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        
            <div className={styles.pagination}>
              <button
                className={styles.page_button}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                &#8592;
              </button>
              <span className={styles.page_number}>
                {currentPage} / {totalPages}
              </span>
              <button
                className={styles.page_button}
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
              >
                &#8594;
              </button>
            </div>
          </div>
        )}
      </div>
      <DeleteConfirmation
        title="Delete Confirmation!"
        body="Do you want to delete this item"
        showModal={showModal}
        hideDeleteModalHandler={hideDeleteModalHandler}
        confirmDeleteModalHandler={confirmDeleteModalHandler}
      />
    </>
  );
};

export default ViewTrackingsShipping;
