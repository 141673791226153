import { React, useEffect, useState } from "react";
import styles from "./AllUser.module.css";
import { getAllUsers , deleteUser, deleteAllUser} from "../../../ToolKit/Features/AUsers/AUsersService";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 20;

const AllUser = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.util);
  const userLists = useSelector((state) => state?.users?.ausers);
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [localLoading, setLocalLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedParcels, setSelectedParcels] = useState(new Set());

  const handleParcelSelect = (parcelId) => {
    const newSelectedParcels = new Set(selectedParcels);
    if (newSelectedParcels.has(parcelId)) {
      newSelectedParcels.delete(parcelId);
    } else {
      newSelectedParcels.add(parcelId);
    }
    setSelectedParcels(newSelectedParcels);
  };

  const handleSelectAll = () => {
    if (selectedParcels.size === userList.length) {
      setSelectedParcels(new Set());
    } else {
      setSelectedParcels(new Set(userList.map((parcel) => parcel.id)));
    }
  };
  const handleSubmitSelected = async () => {
    if (selectedParcels.size > 0) {
     await deleteAllUser(Array.from(selectedParcels));
     await dispatch(getAllUsers());
    }
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        //dispatch(getOrderInreviewByStatus(event.target.value));
      }, 500)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllUsers());
      setLocalLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!localLoading) {
      setUserList(userLists);
      setTotalPages(Math.ceil((userLists?.length || 0) / ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }, [localLoading, userLists]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await dispatch(deleteUser(id));
      // Refresh the data after deletion
      await dispatch(getAllUsers());
    }
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  if (localLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : userList?.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h6>Registered Users</h6>
          <input
            type="text"
            placeholder="Search by username..."
            value={searchInput}
            onChange={handleSearchInput}
          />
          <table className={styles.user_table}>
            <thead>
              <tr>
              <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedParcels.size === userList.length}
                  />
                </th>
                <th>ID</th>
                <th>Username</th>
                <th>Email</th>
                <th>View</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {userList.slice(startIdx, endIdx).map((userp, idx) => (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedParcels.has(userp.id)}
                      onChange={() => handleParcelSelect(userp.id)}
                    />
                  </td>
                  <td data-label="ID">{userp?.id}</td>
                  <td data-label="Username">{userp?.username}</td>
                  <td data-label="Email">{userp?.email}</td>
                  <td
                    onClick={() => {
                      navigate(`/users/${userp?.id}`);
                    }}
                    data-label="Edit"
                  >
                    <button className={styles.edit_btn}>View</button>
                  </td>
                  <td>
                    <button className={styles.edit_btn}  onClick={() => {
                        handleDelete(userp.id);
                      }}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className={styles.edit_btn}
            onClick={handleSubmitSelected}
            disabled={selectedParcels.size === 0}
          >
            Delete Selected Users
          </button>
          {/* 
          <div className={styles.container}>
            {userList.slice(startIdx, endIdx).map((userp, idx) => (
              <div
                className={styles.tile}
                onClick={() => {
                  navigate(`/users/${userp?.id}`);
                }}
                key={userp?.id}
              >
                <h2 className={styles.h2}>
                  User <span className={styles.order_id}>#{userp?.id}</span>
                </h2>

                <p className={styles.date}>User Name: {userp?.username}</p>
                <p className={styles.date}>User Name: {userp?.email}</p>
              </div>
            ))}
          </div>
          */}
          <div className={styles.pagination}>
            <button
              className={styles.page_button}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &#8592;
            </button>
            <span className={styles.page_number}>
              {currentPage} / {totalPages}
            </span>
            <button
              className={styles.page_button}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllUser;
