import {
  CreateParcel,
  EditParcelOrder,
  GetParcelOrders,
  GetParcelById,
  DeleteParcelById,
} from "../../ApiRequest/Api/Parcel";

import { setParcelById, setParcels } from "./ParcelSlice";

import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";
import { dispatch } from "../../Store";
import { setLoading } from "../../utils/UtilSlice";

export const createParcel = (data, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await CreateParcel(data);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getParcelOrder = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelOrders();
    if (res?.data) {
      dispatch(setParcels(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getParcelOrdersById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelById(id);
    dispatch(setLoading(false));
    dispatch(setParcelById(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const editParcelOrder = (id, data) => async () => {
  try {
    const res = await EditParcelOrder(id, data);
    if (res) {
      toast.success(res.data.message);
      dispatch(getParcelOrder());
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const deleteParcelOrder = (id) => async () => {
  try {
    const res = await DeleteParcelById(id);
    if (res) {
      toast.success(res?.data?.message);
      dispatch(getParcelOrder());
    }
  } catch (error) {
    Errorhandler(error);
  }
};
