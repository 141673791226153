import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editUser, getUserById } from "../../../ToolKit/Features/AUsers/AUsersService";
import styles from "./EditMyParcel.module.css";

const EditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const userData = useSelector((state) => state.users.ausersById);

  const [username, setUsername] = useState("");
 

  useEffect(() => {
    if (userData) {
      setUsername(userData?.username);
    
    }
  }, [userData]);

  const updateUser = (e) => {
    e.preventDefault();
    let payload = {
      username: username,
    };
    dispatch(editUser(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getUserById (id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>User Details</h6>
          <form className={styles.form} onSubmit={updateUser}>
            <label className={styles.label} htmlFor="quantity">
              Username
            </label>
            <input
              className={styles.input}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />


            <br></br>
            <div className={styles.buttons_container}>
              <button className={styles.button}>Update User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditUser;
