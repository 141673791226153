import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ausers: null,
  ausersById: null,
};

const auserSlice = createSlice({
  name: "auser",
  initialState,
  reducers: {
    setAusers: (state, action) => {
      state.ausers = action.payload;
    },
    setAusersById: (state, action) => {
      state.ausersById = action.payload;
    },
  },
});

export const {
    setAusers,
    setAusersById
} = auserSlice.actions;

export default auserSlice.reducer;
