import React, { useEffect } from "react";
import "./Shipped.css";
import { getOrderInreviewByStatus } from "../../../ToolKit/Features/Review/ReviewService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Shipped = () => {
  const status = useSelector((state) => state.review.reviewByStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrderInreviewByStatus("Shipped"));
  }, [dispatch]);

  return (
    <div class="userlist_container">
      {status?.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div class="container">
          {status?.map((statuss) => (
            <div class="tile">
              <h2>Order-Details</h2>
              <p>Order ID: #{statuss?.id}</p>
              <p>Order Status: Processing</p>
              <p>Payment Status: Paid</p>
              <button
                onClick={() => {
                  navigate(`/unit-order-review/${statuss?.id}`);
                }}
              >
                View
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Shipped;
