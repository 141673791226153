import api from "../axiosRequest";

export const CreateInitialPictureOrderApi = async (data) => {
  return api.post("pictureorders/create-order-picture", data);
};

export const GetPictureOrderListApi = async (pageNo, pageSize) => {
  return api.get(
    `pictureorders/list-picture-orders?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};

export const GetPictureOrderListDetailApi = async (id, pageNo, pageSize) => {
  return api.get(
    `pictureorders/pictures-by-user/${id}?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};

export const GetPictureOrderListById = async (id) => {
  return api.get(
    `pictureorders/picture-order/${id}`
  );
};

export const GetPictureOrderListDetail_IdApi = async (id) => {
  return api.get(`pictureorders/${id}`);
};

export const EditPictureOrderListDetail_IdApi = async (id, data) => {
  return api.patch(`pictureorders/${id}`, data);
};

export const DeletePictureOrderApi = async (id) => {
  return api.delete(`pictureorders/picture-order/${id}`);
};

export const EditPictureOrderApi = async (id, data) => {
  return api.patch(`pictureorders/picture-order/${id}`, data);
};

export const DeleteSinglePictureOrderApi = async (id) => {
  return api.delete(`pictureorders/${id}`);
};

// <!------>
export const CreateOrderApi = async (data) => {
  return api.post("orders/send-links", data);
};

export const getOrderDetailApi = async (id) => {
  return api.get(`orders/${id}`);
};

export const getOrderApi = (pageNo, pageSize) => {
  return api.get(`orders/list-orders?pageNo=${pageNo}&pageSize=${pageSize}`);
};

export const editOrderApi = async (id, data) => {
  return api.put(`orders/${id}`, data);
};
