import React from "react";
import { useDispatch } from "react-redux";
import styles from "./DeleteModal.module.css";
import { deleteParcelOrder } from "../../../../ToolKit/Features/Parcel/ParcelService";

import trash from "./assets/trash.svg"

const DeleteModal = ({ setDeleteMode, id }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteParcelOrder(id));
    setDeleteMode(false);
  };
  return (
    <div className={styles.delete_modal_container}>
      <div className={styles.delete_modal}>
        <div className={styles.delete_icon}>
          <img src={trash} alt="" />
        </div>
        <p>Remove Order</p>
        <p>Are you sure you want to remove this order from the list?</p>

        <div className={styles.btn_cont}>
          <button onClick={() => setDeleteMode(false)}>No, Keep</button>
          <button onClick={handleDelete}>Yes, Remove</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
