import api from "../axiosRequest";


export const AllUsers = async () => {
  return api.get(`/admin/user/list-users`);
};

export const DeleteUser = async (id) => {
  return api.delete(`/admin/user/${id}`);
};

export const UserById = async (id) => {
  return api.get(`/admin/user/${id}`);
};

export const UserByIdAll = async (id) => {
  return api.delete(`/admin/user/delete-selected?id=${id}`);
};

export const EditUser = async (id, data) => {
  return api.patch(`/admin/user/${id}`, data);
};
