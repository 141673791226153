import { AllUsers, UserById, DeleteUser, UserByIdAll, EditUser } from "../../ApiRequest/Api/Users";

import { setAusers, setAusersById } from "./AUsersSlice";

import Errorhandler from "../../ApiRequest/Errorhandler";
import { dispatch } from "../../Store";
import { setLoading } from "../../utils/UtilSlice";
import { toast } from "react-toastify";

export const getAllUsers = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await AllUsers();
    if (res?.data) {
      dispatch(setAusers(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getUserById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await UserById(id);
    dispatch(setLoading(false));
    dispatch(setAusersById(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const editUser = (id, data) => async () => {
  try {
    const res = await EditUser(id, data);
    if (res) {
      toast.success(res.data.message);
      dispatch(getAllUsers());
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const deleteUser = (id,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await DeleteUser (id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const deleteAllUser = async (id,setTrackResponse) => {
  dispatch(setLoading(true));
  try {
    const res = await UserByIdAll(id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};
