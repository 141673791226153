import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newReview: null,
  reviews: null,
  patchReview: null,
  reviewById: null,
  reviewByStatus: null,
  reviewByDetails: null,
  reviewByCounts: null,
  parcelsReview:null,
  parcelsReviewId:null,
  trackings: null,
  shipping: null,
  shippingById: null,
  shippingDetails: null,
  trackship: null,
  trackshipId: null,
  paystack: null,
  bank: null,
  bankId: null,
  paystackId: null,

};

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setShipping: (state, action) => {
      state.shipping = action.payload;
    },
    setShippingById: (state, action) => {
      state.shippingById = action.payload;
    },
    setPaystack: (state, action) => {
      state.paystack = action.payload;
    },
    setPaystackId: (state, action) => {
      state.paystackId = action.payload;
    },
    setBank: (state, action) => {
      state.bank = action.payload;
    },
    setBankId: (state, action) => {
      state.bankId = action.payload;
    },
    setShippingDetails: (state, action) => {
      state.shippingDetails = action.payload;
    },
    setTrackship: (state, action) => {
      state.trackship = action.payload;
    },
    setTrackshipId: (state, action) => {
      state.trackshipId = action.payload;
    },
    setNewReview: (state, action) => {
      state.newReview = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setTrackings: (state, action) => {
      state.trackings = action.payload;
    },
    setPatchReview: (state, action) => {
      state.patchReview = action.payload;
    },
    setReviewbyId: (state, action) => {
      state.reviewById = action.payload;
       // find the index of the review in the state.reviews array
  const index = state.reviews.findIndex(p => p.id === action.payload.id);
  // update the review in the state.reviews array
  state.reviews[index] = action.payload;

    },
    setReviewbyDetails: (state, action) => {
      state.reviewByDetails = action.payload;
    },
    setReviewByStatus: (state, action) => {
      state.reviewByStatus = action.payload;
    },

    setReviewByCounts: (state, action) => {
      state.reviewByCounts = action.payload;
    },
    setParcelsReview: (state, action) => {
      state.parcelsReview = action.payload;
    },

    setParcelsReviewId: (state, action) => {
      
      state.parcelsReviewId = action.payload;
    },
  },
});

export const {
  setNewReview,
  setPatchReview,
  setReviewByStatus,
  setReviewbyId,
  setReviews,
  setReviewbyDetails,
  setReviewByCounts,
  setParcelsReview,
  setParcelsReviewId,
  setTrackings,
  setShipping,
  setShippingById,
  setShippingDetails,
  setTrackship,
  setTrackshipId,
  setPaystack,
  setBank,
  setBankId,
  setPaystackId,



} = reviewSlice.actions;

export default reviewSlice.reducer;
