import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  referralStatus: null,
  exchangeAll:null,
  exchangeId:null,
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
 
    setReferralStatus: (state, action) => {
      state.referralStatus = action.payload;
    },
    setExchangeAll: (state, action) => {
      state.exchangeAll = action.payload;
    },
    setExchangeId: (state, action) => {
      state.exchangeId = action.payload;
    },

  
  },
});

export const {
  setReferralStatus,
  setExchangeAll,
  setExchangeId,
 
} = referralSlice.actions;

export default referralSlice.reducer;
