import React, { useEffect, useState } from "react";
import "./ViewOneParcel.css";
import { useParams, useNavigate } from "react-router-dom";

import { getParcelOrdersById} from "../../../../ToolKit/Features/Parcel/ParcelService";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader/Loader";
import DeleteModal from "../DeleteComponent/DeleteModal";

import { saveParcelOrder } from "../../../../ToolKit/Features/Review/ReviewService";

const ViewOneParcel = () => {
  const { id } = useParams();
  const parcel = useSelector((state) => state.parcel.parcelById);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.util);

  const [deleteMode, setDeleteMode] = useState(false);
  const [iid, setId] = useState("");

  

  useEffect(() => {
    localStorage.setItem("parcelId", id);
    dispatch(getParcelOrdersById(id));
  }, [dispatch, id]);

  const submitParcelReview = () => {
    dispatch(saveParcelOrder(parcel.id))
  }

  if (!parcel) {
    return <div>Loading....</div>;
  }

  const handleDelete = (iid) => {
    setId(iid);
    setDeleteMode(true);
  };

  
  return (
    <div class="userlist-container">
      {deleteMode && <DeleteModal setDeleteMode={setDeleteMode} id={iid} />}
      {isLoading ? (
        <Loader />
      ) : (
        <div class="card">
          <div class="card-header">
            <h2>Parcel Details</h2>
            <span>Parcel ID: #{parcel?.id}</span>
          </div>
          <div class="card-body">
            <div class="order-item">
              <h3>Number: {parcel?.parcelNumber}</h3>
              <p>Quantity: {parcel?.quantity}</p>
              <p>Request inspection: {parcel?.requestInspection}</p>
              <p>Other Information: {parcel?.otherInformation}</p>
            </div>
            <div class="order-details-btn-section">
              <button
                onClick={() => {
                  navigate(`/updateorder-parcel/${parcel?.id}`);
                }}
                class="order-details-btn"
              >
                Edit
              </button>
              <button onClick={submitParcelReview} class="order-details-btn">Request Shipping</button>
              <button onClick={(event) => { event.stopPropagation();  handleDelete(parcel.id); } } class="payment-btn">Delete</button>
                
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewOneParcel;
