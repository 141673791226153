import {
  SaveLinkOrder,
  SavePictureOrder,
  SaveParcelOrder,
  EditOrdesInReview,
  Download,
  GetOrdersInReview,
  GetOrdersInReviewByStatus,
  GetOrdersInReviewById,
  GetOrdersInReviewByDetails,
  GetOrdersCountByStatus,
  InitializePayment,
  EditParcelsInReviewById,
  GetParcelsInReview,
  GetParcelsInReviewById,
  TrackingId,
  GetTrackingId,
  DeleteTracking,
  SaveParcelInWarehouse,
  GetParcelsInShipping,
  GetParcelsShippingInReviewById,
  EditShippingInReview,
  GetShippingInReviewByDetails,
  TrackingIdShipping,
  GetTrackingIdShipping,
  DeleteTrackingShipping,
  GetTopUp,
  GetTopById,
  EditTopUp,
  GetTopUpPayStack,
  GetTopPaystackById,
  EditTopPaystackUp,
  GetAllNotifications,
  GetAllNotificationsById,
  EditGetAllNotifications,
  CreateNotifications,
  DeleteNotification,
} from "../../ApiRequest/Api/Review";

import { setPaystack, setBank, setBankId, setPaystackId, setShipping, setShippingById, setShippingDetails, setTrackship,setReviewByStatus, setTrackings, setReviewbyId, setReviews, setReviewbyDetails, setReviewByCounts, setParcelsReview,setParcelsReviewId } from "./ReviewSlice";

import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";
import { dispatch } from "../../Store";
import { setLoading } from "../../utils/UtilSlice";

export const saveLinkOrder = (id,data, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await SaveLinkOrder(id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const saveParcelInWarehouse = async (id,setTrackResponse) => {
  dispatch(setLoading(true));
  try {
    const res = await SaveParcelInWarehouse(id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};


export const initializePayment = (id,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await InitializePayment(id);
    toast.success(res?.data?.message);
    if (res.status === 200) window.open(res.data, '_blank');;
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const download = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await Download(id);
    toast.success(res?.data?.message);
    if (res.status === 200)
    {
      const blob = new Blob([res.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'order-export-' + id + '.csv';
      link.click();
    }
     //window.open(res.data, '_blank');;
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const savePictureOrder = (id,data, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await SavePictureOrder(id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const saveParcelOrder = (id, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await SaveParcelOrder(id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getOrderInreview = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetOrdersInReview();
    if (res?.data) {
      dispatch(setReviews(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getParcelsInShipping = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelsInShipping();
    if (res?.data) {
      dispatch(setShipping(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getOrderInreviewById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetOrdersInReviewById(id);
    dispatch(setLoading(false));
    dispatch(setReviewbyId(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const getParcelsShippingInreviewById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelsShippingInReviewById(id);
    dispatch(setLoading(false));
    dispatch(setShippingById(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const getOrdersInReviewByDetails = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetOrdersInReviewByDetails(id);
    dispatch(setLoading(false));
    dispatch(setReviewbyDetails(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getShippingInReviewByDetails = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetShippingInReviewByDetails(id);
    dispatch(setLoading(false));
    dispatch(setShippingDetails(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};



export const getOrderInreviewByStatus = (status) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetOrdersInReviewByStatus(status);
    dispatch(setLoading(false));
    dispatch(setReviewByStatus(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getOrdersCountByStatus = (status) => async() => {
  dispatch(setLoading(true));
  try {
    const res = await GetOrdersCountByStatus(status);
    dispatch(setLoading(false));
    dispatch(setReviewByCounts(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }

};

export const editOrderInReview = (id, data) => async () => {
  try {
    const res = await EditOrdesInReview(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = `/all-order-review`
      dispatch(getOrderInreview());
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const editShippingInReview = (id, data) => async () => {
  try {
    const res = await EditShippingInReview(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = `/in-shipping`
      dispatch(getParcelsInShipping);
    }
  } catch (error) {
    Errorhandler(error);
  }
};




export const getParcelsInreview = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelsInReview();
    if (res?.data) {
      dispatch(setParcelsReview(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const editParcelsInReviewById = (id, data) => async () => {
  try {
    const res = await EditParcelsInReviewById(id, data);
    if (res) {
      toast.success(res.data.message);
      dispatch(getParcelsInreview());
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const getParcelsInById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetParcelsInReviewById(id);
    dispatch(setLoading(false));
    dispatch(setParcelsReviewId(res?.data));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const trackingId = (id,data, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await TrackingId (id, data);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const trackingShippingId = (id,data, setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await TrackingIdShipping (id, data);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getTrackingId = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTrackingId(id);
    dispatch(setLoading(false));
    dispatch(setTrackings(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const getTrackingIdShipping = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTrackingIdShipping(id);
    dispatch(setLoading(false));
    dispatch(setTrackship(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const deleteTrackingId = (id,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await DeleteTracking (id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const deleteTrackingShipping = (id,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await DeleteTrackingShipping (id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getTopUp = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTopUp();
    if (res?.data) {
      dispatch(setBank(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getTopUpPaystack = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTopUpPayStack();
    if (res?.data) {
      dispatch(setPaystack(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getAllNotification = () => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetAllNotifications();
    if (res?.data) {
      dispatch(setParcelsReview(res?.data));
    } else {
      console.log("Response data is null or undefined");
    }

    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const getTopById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTopById(id);
    dispatch(setLoading(false));
    dispatch(setBankId(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const getTopUpPaystackById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetTopPaystackById(id);
    dispatch(setLoading(false));
    dispatch(setPaystackId(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const getNotificationById = (id) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await GetAllNotificationsById(id);
    dispatch(setLoading(false));
    dispatch(setTrackship(res?.data));
    return res;
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
    return error;
  }
};

export const editTopUp = (id, data) => async () => {
  try {
    const res = await EditTopUp(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = `/bank`
      dispatch(getParcelsInShipping);
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const editTopPaystackUp = (id, data) => async () => {
  try {
    const res = await EditTopPaystackUp(id, data);
    if (res) {
      toast.success(res.data.message);
      window.location.href = `/paystack`
      dispatch(getParcelsInShipping);
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const editGetAllNotifications = (id, data) => async () => {
  try {
    const res = await EditGetAllNotifications(id, data);
    if (res) {
      toast.success(res.data.message);
      dispatch(getParcelsInShipping);
    }
  } catch (error) {
    Errorhandler(error);
  }
};

export const deleteNotification = (id,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await DeleteNotification (id);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};

export const createNotifications = (data,setTrackResponse) => async () => {
  dispatch(setLoading(true));
  try {
    const res = await CreateNotifications (data);
    toast.success(res?.data?.message);
    if (res.status === 200) setTrackResponse(true);
    dispatch(setLoading(false));
  } catch (error) {
    Errorhandler(error);
    dispatch(setLoading(false));
  }
};