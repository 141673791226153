import React from "react";
import styles from "./Procurement.module.css";
import { useNavigate } from "react-router-dom";

const Procurement = () => {
  const navigate = useNavigate();

  //all-order-review

  return (
    <div className={styles.userlist_container}>
      <h2>Procurement</h2>
      <div className={styles.container}>
        <div
          onClick={() => {
            navigate(`/all-order-review`);
          }}
          className={styles.tile}
        >
          <i class="icon fas fa-clipboard-list">
            <img src="https://img.icons8.com/ios/40/null/submit-for-approval.png" alt=""/>
          </i>
          <div>Submmitted Orders</div>
        </div>
        <div className={styles.tile}>
          <i class="icon fas fa-shipping-fast">
            <img src="https://img.icons8.com/color/40/null/hourglass.png" alt=""/>
          </i>
          <div>Pending Payment</div>
        </div>
        <div
          onClick={() => {
            navigate(`/in-progress`);
          }}
          className={styles.tile}
        >
          <i class="icon fas fa-store-alt">
            <img src="https://img.icons8.com/color/40/null/in-progress--v1.png" alt=""/>
          </i>
          <div>Orders in Progress</div>
        </div>
        <div 
        onClick={() => {
          navigate(`/ship`);
        }}
        className={styles.tile}>
          <i class="icon fas fa-money-bill-wave">
            <img src="https://img.icons8.com/color/48/null/shipped.png" alt=""/>
          </i>
          <div> Shipped Order</div>
        </div>
        <div onClick={() => {
            navigate(`/closed`);
          }}
          className={styles.tile}>
          <i class="icon fas fa-truck">
            <img src="https://img.icons8.com/color/40/null/closed-sign.png" alt=""/>
          </i>
          <div>Closed Order</div>
        </div>
        <div className={styles.tile}>
          <i class="icon fas fa-route">
            <img src="https://img.icons8.com/color/40/null/order-shipped.png" alt=""/>
          </i>
          <div>Track Order</div>
        </div>
        <div className={styles.tile}>
          <i class="icon fas fa-shipping-fast">
            <img src="https://img.icons8.com/color/40/null/all.png" alt=""/>
          </i>
          <div>All Order</div>
        </div>
      </div>
    </div>
  );
};
export default Procurement;
