import api from "../../ApiRequest/axiosRequest";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Errorhandler from "../../ApiRequest/Errorhandler";

export const fetchAllListOrdersLink = createAsyncThunk("/api/linkorders/list-orders-link", async () => {
    const response = await api.get("linkorders/list-orders-link?pageNo=0&pageSize=500");
    return response.data;
  });

  


  export const fetchOneOrderLink = createAsyncThunk("linkorders/order", async (id) => {
    const response = await api.get(`linkorders/order/${id}`);
    return response.data;
  });

  



  export const createOrderLink = createAsyncThunk(
    "/api/linkorders/create-order-links",
    async (payload) => {

        try {
            const response = await api.post("linkorders/create-order-links", payload);
            if (response) {
              toast.success(response?.data?.message);
              window.location.href = "/list-orders";
              return response.data;
            }
          } catch (error) {
            Errorhandler(error);
          }
    }
  );

 

  export const deleteOrderLink = createAsyncThunk("linkorders/order-link", async (id) => {

    
    try {
      const response = await api.delete(`linkorders/order-link/${id}`);
      if (response) {
        toast.success(response?.data?.message);
        return id;
      }
    } catch (error) {
      Errorhandler(error);
    }
    
  });


  export const updateOrderLink = createAsyncThunk("linkorders/update/order", async (payload) => {
    
    
    try {
      const response = await api.patch(
        `linkorders/update/order/${payload.id}`,
        payload
      );
      if (response) {
        toast.success(response?.data?.message);
        window.location.href = "/list-orders";
        return response.data;
      }
    } catch (error) {
      Errorhandler(error);
    }

  });
  





  const initialState = {
    linkOrderData: [],
    loading: "idle",
  };

  const linkOrderSlice = createSlice({
    name: "linkOrders",
    initialState,
    reducers: {},

    extraReducers: (builder) => {

        builder.addCase(fetchAllListOrdersLink.pending, (state, action) => {
            state.loading = "pending";
          });

          builder.addCase(fetchAllListOrdersLink.fulfilled, (state, action) => {
            state.loading = "idle";
            state.linkOrderData = action.payload;
          });

          builder.addCase(fetchOneOrderLink.pending, (state, action) => {
            state.loading = "pending";
          });
      
          builder.addCase(fetchOneOrderLink.fulfilled, (state, action) => {
            state.loading = "idle";
          });
      
   
          builder.addCase(createOrderLink.pending, (state, action) => {
            state.loading = "pending";
          });
          builder.addCase(createOrderLink.fulfilled, (state, action) => {
            state.loading = "idle";
            state.linkOrderData.unshift(action.payload);
          });

          builder.addCase(deleteOrderLink.pending, (state) => {
            state.loading = "pending";
          });
          builder.addCase(deleteOrderLink.fulfilled, (state, action) => {
            state.loading = "idle";
            state.linkOrderData = state.linkOrderData.filter((_) => _.id !== action.payload);
          });

          builder.addCase(updateOrderLink.pending, (state) => {
            state.loading = "pending";
          });
          builder.addCase(updateOrderLink.fulfilled, (state, action) => {
            state.loading = "idle";
            state.linkOrderData = state.linkOrderData.filter((_) => _.id !== action.payload.id);
            state.linkOrderData.unshift(action.payload);
          });
   
  
    }})


    export const getAllLinkOrders = (state) => state.link.linkOrderData;
    export const getLoading = (state) => state.link.loading;
    export const getLinkById = (id) => {
      return (state) => state.link.linkOrderData.filter((_) => _.id === id)[0];
    };

    export const getOneLinkById = (state, id) =>
  state.link.linkOrderData.find(link => link.id === id);

    export default linkOrderSlice.reducer;
  