import { React, useEffect, useState } from "react";
import styles from "./ParcelInReview.module.css";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getParcelsInShipping } from "../../../ToolKit/Features/Review/ReviewService";
import { useNavigate } from "react-router-dom";


const ITEMS_PER_PAGE = 10;

const RequestShipping = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.util);
  const parcelLists = useSelector((state) => state?.review?.shipping);
  const [parcelList, setParcelList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [localLoading, setLocalLoading] = useState(true);
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedParcels, setSelectedParcels] = useState(new Set());


  const handleParcelSelect = (parcelId) => {
    const newSelectedParcels = new Set(selectedParcels);
    if (newSelectedParcels.has(parcelId)) {
      newSelectedParcels.delete(parcelId);
    } else {
      newSelectedParcels.add(parcelId);
    }
    setSelectedParcels(newSelectedParcels);
  };

  const handleSelectAll = () => {
    if (selectedParcels.size === parcelList.length) {
      setSelectedParcels(new Set());
    } else {
      setSelectedParcels(new Set(parcelList.map((parcel) => parcel.id)));
    }
  };
  const handleSubmitSelected = async () => {
    if (selectedParcels.size > 0) {
    
    }
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        //dispatch(getOrderInreviewByStatus(event.target.value));
      }, 500)
    );
  };


  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getParcelsInShipping());
      setLocalLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if(!localLoading) {
    setParcelList(parcelLists);
    setTotalPages(Math.ceil(parcelLists.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  }
  }, [localLoading,parcelLists]);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  if (localLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : parcelList?.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h2>To Be Shipped</h2>
          <input
            type="text"
            placeholder="Search by trackcode..."
            value={searchInput}
            onChange={handleSearchInput}
          />
          <table className={styles.user_table}>
            <thead>
              <tr>
              <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedParcels.size === parcelLists.length}
                  />
                </th>
                <th>ID</th>
                <th>Shipment ID</th>
                <th>View</th>
               
             
              </tr>
            </thead>
            <tbody>
              {parcelList.slice(startIdx, endIdx).map((review, idx) => (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedParcels.has(review.id)}
                      onChange={() => handleParcelSelect(review.id)}
                    />
                  </td>
                  <td data-label="ID">{review?.id}</td>
                  <td data-label="Parcel Number">{review?.trackCode}</td>
                 <td
                    onClick={() => {
                      navigate(`/unit-parcel-shipping/${review?.id}`);
                    }}
                    data-label="Edit"
                  >
                    <button className={styles.edit_btn}>View</button>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className={styles.edit_btn}
            onClick={handleSubmitSelected}
            disabled={selectedParcels.size === 0}
          >
            Confirm Availability
          </button>
          {/* 
          <div className={styles.container}>
            {parcelList.slice(startIdx, endIdx).map((review, idx) => (
              <div
                className={styles.tile}
                onClick={() => {
                  navigate(`/list-one-parcels/${review?.id}`);
                }}
                key={review?.id}
              >
                <h2 className={styles.h2}>
                  Order{" "}
                  <span className={styles.order_id}>
                    #{review?.parcelNumber}
                  </span>
                </h2>
                <p className={styles.date}>Date: </p>
                <p className={styles.status}>Status: </p>
                <p className={styles.price}>$</p>
              </div>
            ))}
          </div>
          */}
          <div className={styles.pagination}>
            <button
              className={styles.page_button}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &#8592;
            </button>
            <span className={styles.page_number}>
              {currentPage} / {totalPages}
            </span>
            <button
              className={styles.page_button}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestShipping;
