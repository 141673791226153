import { React, useEffect, useState } from "react";
import { getOrdersInReviewByDetails } from "../../../ToolKit/Features/Review/ReviewService";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./DetailsUnitReview.module.css";

const ITEMS_PER_PAGE = 5;

const DetailsUnitReview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.util);
  const reviewLists = useSelector((state) => state?.review?.reviewByDetails);
  const [reviewList, setReviewList] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    localStorage.setItem('reviewId', id);
    dispatch(getOrdersInReviewByDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setReviewList(reviewLists);
    setTotalPages(Math.ceil(reviewLists.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  }, [reviewLists]);



  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIdx = currentPage * ITEMS_PER_PAGE;

  return (
    <div className={styles.userlist_container}>
      {isLoading ? (
        <Loader />
      ) : reviewList.length === 0 ? (
        <p>Nothing to Show</p>
      ) : (
        <div>
          <h2>Procurement</h2>
          <div className={styles.container}>
            {reviewList.slice(startIdx, endIdx).map((review, idx) => (
              <div key={review?.id} className={styles.tile}>
                <h2 className={styles.h2}>
                  Order <span className={styles.order_id}>#{review?.id}</span>
                </h2>
                <p>Date: {review?.orderDate}</p>
                <p>Status: {review?.quantity}</p>
                <p>${review?.totalPrice}</p>
                <p>${review?.link}</p>
              </div>
            ))}
          </div>
          <div className={styles.pagination}>
            <button
              className={styles.page_button}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &#8592;
            </button>
            <span className={styles.page_number}>
              {currentPage} / {totalPages}
            </span>
            <button
              className={styles.page_button}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &#8594;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsUnitReview;
