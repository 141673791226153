import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newParcel: null,
  parcels: null,
  patchParcel: null,
  parcelById: null,
 
};

const parcelSlice = createSlice({
  name: "parcel",
  initialState,
  reducers: {
    setNewParcel: (state, action) => {
      state.newParcel = action.payload;
    },
    setParcels: (state, action) => {
      state.parcels = action.payload;
    },
    setPatchParcel: (state, action) => {
      state.patchParcel = action.payload;
    },
    setParcelById: (state, action) => {
      state.parcelById = action.payload;

    },
   
  },
});

export const {
  setNewParcel,
  setPatchParcel,
  setParcelById,
  setParcels,
} = parcelSlice.actions;

export default parcelSlice.reducer;
