import { React, useState, useEffect } from "react";
//import { getCurrentUser } from "../../services/auth";
import styles from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";



const Dashboard = () => {
  //const [currentUser] = useState(getCurrentUser());

  const navigate = useNavigate();

  

  const [copied, setCopied] = useState(false);
  //const [conversionRate, setConversionRate] = useState(null);

/*
  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const response = await fetch(
          "https://api.exchangerate-api.com/v4/latest/CNY"
        );
        const data = await response.json();
        setConversionRate(data.rates.NGN);
      } catch (error) {
        console.log("Error fetching conversion rate:", error);
      }
    };

    fetchConversionRate();
  }, []);
*/



  // use useEffect hook to reset the copied state to false after 3 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  

  return (
    <div className={styles.userlist_container}>
     
      <div>
      <h6>Admim/Moderator Dashboard</h6>
        <div className={styles.container}>
          
          <div className={styles.column}>
            <div className={styles.card}>
              <h3 className={styles.h33}>Manage Procurements</h3>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/all-order-review");
                }}
              >
                Orders in Review
              </button>
              <br />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.card}>
              <h3 className={styles.h33}>Manage Consolidation</h3>

              <button
                className={styles.button}
                onClick={() => {
                  navigate("/list-of-parcels");
                }}
              >
                Confirm Parcel
              </button>
            </div>
          </div>
          <div className={styles.column}>
          <div className={styles.card}>
              <h3 className={styles.h33}>Request Shipping</h3>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/in-shipping");
                }}
              >
                Waiting to be Shipped
              </button>
              <br />
            </div>
          </div>

        
          <div className={styles.column}>
            {/** 
            <div className={styles.card}>
              <h3 className={styles.h33}>Exchange Rate</h3>
              <p className={styles.pp}>
                <div>
                  <h6>Chinese Yuan to Naira Conversion Rate</h6>
                  {conversionRate ? (
                    <p>1 CNY = {conversionRate.toFixed(2)} NGN</p>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </p>
            </div>
            */}
            
            <div className={styles.card}>
              <h3 className={styles.h33}>User Management</h3>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/users");
                }}
              >
                User Details
              </button>
            </div>
            <div className={styles.card}>
              <h3 className={styles.h33}>Confirm Top Up</h3>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/bank");
                }}
              >
                Confirm Bank Payment
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/paystack");
                }}
              >
                Confirm Paystack Payment
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  navigate("/create-exchange");
                }}
              >
                Update Exchange Rate
              </button>
            </div>


          </div>
          
          
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
