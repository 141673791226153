import { getCurrentUser } from "../../services/auth";
import { getReferralSystem } from "../../ToolKit/Features/Referral/ReferralService";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Referral = () => {

    const refers = useSelector((state) => state.referral.referralStatus);
    const dispatch = useDispatch();

    const currentUser = getCurrentUser();

    useEffect(() => {
        dispatch(getReferralSystem(currentUser?.referralCode));
      });


    return(
        <div class="userlist_container">
      {refers?.length === 0 ? (
        <p>You've Not Refer Anyone</p>
      ) : (
        <div class="container">
          {refers?.map((statuss) => (
            <div class="tile">
             
              <p>Username: #{statuss?.username}</p>
              
            </div>
          ))}
        </div>
      )}
    </div>

    );
} 
export default Referral;