import React from "react";
import styles from "./Consolidation.module.css";
import { useNavigate } from "react-router-dom";

const Consolidation = () => {
  const navigate = useNavigate();

  //all-order-review

  return (
    <div className={styles.userlist_container}>
      <h2>Consolidation</h2>
      <div className={styles.container}>
        <div
          className={styles.tile}
          onClick = {
            () => {
              navigate(`/list-of-parcels`)
            }
          }
        >
          <i class="icon fas fa-clipboard-list">
          <img src="https://img.icons8.com/fluency/40/null/send-box.png" alt=""/>
          </i>
         
          <div>All Parcels</div>
        </div>
        <div className={styles.tile}>
          <i class="icon fas fa-shipping-fast">
          <img src="https://img.icons8.com/color/40/null/garage-closed.png" alt=""/>
          </i>
          <div>Parcel in Warehouse</div>
        </div>
        <div
         
          className={styles.tile}

          onClick = {
            () => {
              navigate(`/in-shipping`)
            }
          }
        >
          <i class="icon fas fa-store-alt">
          <img src="https://img.icons8.com/fluency/40/null/indoor-parking.png" alt=""/>
          </i>
          <div>Requested for Shipping</div>
        </div>
        <div 
       
        className={styles.tile}>
          <i class="icon fas fa-money-bill-wave">
          <img src="https://img.icons8.com/matisse/40/null/experimental-shipped-matisse.png" alt=""/>
          </i>
          <div> Shipped Parcels</div>
        </div>
      </div>
    </div>
  );
};
export default Consolidation;
