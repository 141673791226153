import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getParcelsInById, editParcelsInReviewById } from "../../../ToolKit/Features/Review/ReviewService";
import styles from "./EditMyParcel.module.css"

const EditMyParcel = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParcelsInById(id));
  }, [dispatch, id]);

  // use selector to get the reviewData from the state
  const parcelData = useSelector((state) => state.review.shippingId);


 
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [channel, setChannel] = useState("");
  const [request, setRequest] = useState("");

  useEffect(() => {
    if (parcelData) {
     
      //
      setName(parcelData?.receiverName);
      setNumber(parcelData?.receiverPhone);
      setAddress(parcelData?.receiverAddress);
      setType(parcelData?.shippmentType);
      setChannel(parcelData?.shippmentChannel);
      setRequest(parcelData?.otherinformation);
    }
  }, [parcelData]); // add reviewData as a dependency

  const updateParcel = (e) => {
    e.preventDefault();
    let payload = {
      receiverName: name,
      receiverAddress: address,
      receiverPhone: number,
      shippmentType: type,
      shippmentChannel: channel,
      otherinformation: request,
    };
    dispatch(editParcelsInReviewById(id, payload))
      .unwrap()
      .then(() => {
        // handle success case
        console.log("Order updated successfully");
        // fetch the order data again
        dispatch(getParcelsInById(id));
      })
      .catch((error) => {
        // handle error case
        console.error("Error updating order", error);
      });
  };

  return (
    <div className={styles.userlist_container}>
      <div className={styles.content_container}>
        <div className={styles.container}>
          <h6>Add Shipping Details</h6>
          <form className={styles.form} onSubmit={updateParcel}>
            
            <label className={styles.label} htmlFor="quantity">
              Receiver Name
            </label>
            <input
              className={styles.input}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />

           <label className={styles.label} htmlFor="quantity">
              Receiver Number
            </label>

             <input
              className={styles.input}
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />

            <label className={styles.label} htmlFor="inspect">
              Receiver Address
            </label>

            <select
              className={styles.input}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              name="inspection"
              id="inspect"
            >
              <option value="">--select--</option>
              <option value="Kano">Kano</option>
              <option value="Lagos">Lagos</option>
              <option value="Onitsha">Onitsha</option>
            </select>

            <label className={styles.label} htmlFor="inspect">
              Shippment Type
            </label>

            <select
              className={styles.input}
              onChange={(e) => setType(e.target.value)}
              value={type}
              name="inspection"
              id="inspect"
            >
              <option value="">--select--</option>
              <option value="Normal">Normal</option>
              <option value="Special">Special</option>
            </select>

            <label className={styles.label} htmlFor="inspect">
              Shippment Channel
            </label>

            <select
              className={styles.input}
              onChange={(e) => setChannel(e.target.value)}
              value={channel}
             
            >
              <option value="">--select--</option>
              <option value="Air">Air</option>
              <option value="Sea">Sea</option>
            </select>

            <label className={styles.label} htmlFor="add">
              Special Request
            </label>
            <textarea
              className={styles.input}
              type="text"
              onChange={(e) => setRequest(e.target.value)}
              value={request}
            />


            <br></br>
            <div className={styles.buttons_container}>
            <button className={styles.button}>Update Parcel</button>
            </div>

           
          </form>
        </div>
      </div>
    </div>
  );
  };
export default EditMyParcel;